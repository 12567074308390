import PropTypes from "prop-types";

const Paragraph = ({ value, table }) => {
  return (
    <p className={` ${table ? "mt-[8px]" : "mt-[16px]"} dpd-text`}>{value}</p>
  );
};

export default Paragraph;

Paragraph.propTypes = {
  value: PropTypes.array,
  table: PropTypes.bool,
};
