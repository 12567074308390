import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
const OnRouteChangeScrollHelper = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        document
          .getElementById(location.hash.slice(1))
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
};
export default OnRouteChangeScrollHelper;
