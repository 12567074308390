import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";

import { PRINTER_TYPES, REGEX } from "../../constants";
import { transformTemplateStrings } from "../../helpers/swagger.transformer";

import { MarkdownComponents } from "../Typography/Markdown";
import CodeBlock from "../Typography/Markdown/CodeBlock";

const NESTED_KEYS = ["properties", "items"];
const indentationClass = [
  "ml-[8px]",
  "ml-[16px]",
  "ml-[24px]",
  "ml-[32px]",
  "ml-[40px]",
  "ml-[48px]",
  "ml-[56px]",
  "ml-[64px]",
  "ml-[72px]",
  "ml-[80px]",
  "ml-[88px]",
  "ml-[96px]",
  "ml-[104px]",
  "ml-[112px]",
];

const getMarkdownDescription = (description) => {
  return (
    <div className="my-2">
      <ReactMarkdown children={description} components={MarkdownComponents} />
    </div>
  );
};

const withParagraph = (text, key) => (
  <p key={key} className="my-2">
    {text}
  </p>
);
const getMinMaxValueDescription = ({ minimum, maximum }) => {
  let descriptions = [];

  if (minimum != null) {
    descriptions.push(`min: ${minimum}`);
  }
  if (maximum != null) {
    descriptions.push(`max: ${maximum}`);
  }
  return descriptions?.length ? (
    <>{descriptions.map((desc, idx) => withParagraph(desc, idx))}</>
  ) : null;
};

const getLengthDescription = ({ minLength, maxLength }) => {
  let descriptions = [];
  if (minLength != null) {
    descriptions.push(`minLength: ${minLength}`);
  }
  if (maxLength != null) {
    descriptions.push(`maxLength: ${maxLength}`);
  }
  return descriptions?.length ? (
    <>{descriptions.map((desc, idx) => withParagraph(desc, idx))}</>
  ) : null;
};

const NestedDiv = ({
  data = {},
  level = 0,
  required = [],
  exampleTypeToShow,
}) => {
  const [isOpen, setIsOpen] = React.useState(() => {
    let initialState = {};
    (data?.properties ? Object.keys(data.properties) : []).forEach((key) => {
      initialState[key] = false;
    });
    return initialState;
  });

  const toggleOpen = (key) =>
    setIsOpen((prevState) => ({ ...prevState, [key]: !prevState[key] }));

  const isRequired = (key, requiredList) => {
    if (requiredList) {
      if (requiredList?.includes(key)) {
        return true;
      } else return false;
    } else if (required.includes(key)) {
      return true;
    } else return false;
  };

  if (
    !data ||
    typeof data !== "object" ||
    (data.hasOwnProperty("properties") && typeof data.properties !== "object")
  ) {
    console.error("Error: Invalid data.");
    return null;
  }

  if (!data.properties) {
    return (
      <div
        className={`${indentationClass[level]} pb-10 text-sm flex items-center mr-10`}
      >
        <p className="w-full sm:w-60">
          {data.title &&
            data.title.toLowerCase().replace(REGEX.CAMELCASE, (match, chr) => {
              return chr ? chr.toUpperCase() : "";
            })}{" "}
          item
        </p>
        <div className={`${indentationClass[level]} flex flex-col pt-6 pb-2`}>
          {data.type !== "object" && (
            <div className="my-2">
              {data.title}{" "}
              <span className="bg-gray-400 text-white rounded-md mt-4 p-0.5">
                {data.type} {data.format && `(${data.format})`}
              </span>
            </div>
          )}
          {getMarkdownDescription(data.description)}
          {data.example && (
            <div className="w-full overflow-y-auto">
              <p className="pt-2">Example:</p>
              <CodeBlock
                language={"JSON"}
                value={
                  PRINTER_TYPES.includes(exampleTypeToShow)
                    ? transformTemplateStrings(data.example)
                    : JSON.stringify(
                        transformTemplateStrings(data.example),
                        null,
                        2
                      )
                }
                maxHeight="max-h-[300px]"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`${indentationClass[level]} pb-10 text-sm`}>
      {Object.entries(data.properties).map(([key, value]) => {
        const isNested =
          NESTED_KEYS.includes(key) ||
          Object.keys(value).some((k) => NESTED_KEYS.includes(k));

        return (
          <div key={key} className="border-b border-gray-300">
            <div className="flex">
              <div
                className={`flex items-center mr-10 ${isNested ? "cursor-pointer" : ""}`}
                onClick={() => toggleOpen(key)}
              >
                {isNested && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className={`w-4 h-4 transform ${
                      isOpen[key] ? "rotate-90" : ""
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                )}
                <p className="w-full sm:w-60">
                  {key}
                  {isRequired(key, data.required) && (
                    <span className="ml-2 text-red-600 align-middle text-[20px]">
                      *
                    </span>
                  )}
                </p>
              </div>
              <div className="flex flex-col pt-6 pb-2">
                {value.type !== "object" && (
                  <div className="my-2">
                    {value.title}{" "}
                    <span className="bg-gray-400 text-white rounded-md mt-4 p-0.5">
                      {value.type} {value.format && `(${value.format})`}
                    </span>
                  </div>
                )}

                {getMarkdownDescription(value.description)}
                {getLengthDescription(value)}
                {value.pattern && (
                  <p className="my-2">
                    pattern:{" "}
                    <span className="text-gray-500 font-thin">
                      {value.pattern}
                    </span>
                  </p>
                )}
                {getMinMaxValueDescription(value)}
                {value.enum && (
                  <p className="my-2">enum: {value.enum.join(", ")}</p>
                )}
                {value.example && (
                  <div className="w-full overflow-y-auto">
                    <p className="pt-2">Example:</p>
                    <CodeBlock
                      language={"JSON"}
                      value={
                        PRINTER_TYPES.includes(exampleTypeToShow)
                          ? transformTemplateStrings(value.example)
                          : JSON.stringify(
                              transformTemplateStrings(value.example),
                              null,
                              2
                            )
                      }
                      maxHeight="max-h-[300px]"
                    />
                  </div>
                )}
              </div>
            </div>
            {isOpen[key] && value.properties && (
              <NestedDiv
                data={value}
                level={level + 1}
                required={value.required}
              />
            )}
            {isOpen[key] && value.items && (
              <NestedDiv
                data={value.items}
                level={level + 1}
                required={value.items.required}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

NestedDiv.propTypes = {
  data: PropTypes.object,
  level: PropTypes.number,
  required: PropTypes.arrayOf(PropTypes.string),
  exampleTypeToShow: PropTypes.string,
};

NestedDiv.defaultProps = {
  data: {},
  level: 0,
  required: [],
};

export default NestedDiv;
