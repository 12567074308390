import * as SECTIONS from "./sections";

const sections = [
  SECTIONS.INTRODUCTION,
  SECTIONS.FLOW_INTEGRATIONS,
  SECTIONS.CHOOSING_THE_CORRECT_API,
  SECTIONS.SANDBOX_ENVIRONMENT_VS_LIVE,
  SECTIONS.SANDBOX_TEST_PARCEL_TRACKING,
  SECTIONS.GET_DEIVERY_IMAGE_API_RESPONSE_FORMAT,
  SECTIONS.RATE_LIMITING,
  SECTIONS.ERROR_HANDLING,
];

export { sections as SECTIONS };
