import React from "react";

import ProductionFlow from "../../components/Svgs/ProductionFlow";
import BodyParagraph from "../../components/Typography/BodyParagraph";

const sections = [
  {
    summary: "Overview",
    body: (
      <>
        <BodyParagraph>
          Following successful testing of your integration via the sandbox
          environment, it is then time to generate test label output via the
          live environment, our Customer Integration team will work with you to
          test your label output once submitted and approve your move to live
          and ability to ship parcels via a sign off process. Once sign off is
          provided you may then start shipping live parcels with DPD / DPD
          Local.
        </BodyParagraph>
      </>
    ),
  },
  {
    summary: "Sign off process",
    body: (
      <>
        <ProductionFlow />
        <BodyParagraph>
          A formal sign-off of labels produced must be obtained from the
          Customer Automation Technical Team before live shipping is accepted.
          The test output presented to DPD for sign off must contain the Live
          details and be generated from the Live environment and printed using
          the printers that will be used in the production environment, this is
          to ensure that the output presented for sign off will be
          representative of what DPD/DPD Local will receive when in the
          production environment.
        </BodyParagraph>
        <BodyParagraph>
          Physical Label Test packs should be sent to the address below
        </BodyParagraph>
        <BodyParagraph>
          FAO: Customer Integration Team (IT Dept)
          <br />
          DPDgroup Ltd
          <br />
          2nd Floor, Hub 3<br />
          Broadwell Road
          <br />
          Oldbury
          <br />
          B69 4DA
          <br />
        </BodyParagraph>
        <BodyParagraph>
          Please ensure a cover note is included with test packs posted along
          with applicable contact details so we are able to identify the sender
          upon arrival and provide any feedback to the correct contact/s.
        </BodyParagraph>
        <BodyParagraph>
          Once these arrive we aim to provide test pack feedback within 48
          hours.
        </BodyParagraph>
        <BodyParagraph>
          Once sign off is obtained It is recommended that the first live runs
          be of small volumes, and monitored by both DPD and the client.
        </BodyParagraph>
      </>
    ),
    messageCards: [
      {
        type: "note",
        message:
          "DPD/DPD Local may revoke the authorisation to ship if subsequent problems arise with data integrity or label quality.",
      },
    ],
  },
  {
    summary: "Production (Live) URL Guidance",
    body: (
      <>
        <BodyParagraph>
          Please see below Live Hostname that can be used once your testing has
          been completed within the test sandbox environment, Using this
          Hostname will allow you to connect to and produce production label
          examples for sign off (to be presented to the Customer Integration
          Team). The endpoints will remain the same, only the Hostname will
          change.
        </BodyParagraph>
        <BodyParagraph>
          Test Hostname (Sandbox) :- {process.env.REACT_APP_SERVICE_URL}
          <br />
          Live Hostname (Live) :- {process.env.REACT_APP_LIVE_SERVICE_URL}
        </BodyParagraph>
      </>
    ),
  },
];

export default sections;
