import { DateTime } from "luxon";

import { DEFAULT_CONTENT_TYPES, HTTP_STATUS, SECURITY } from "../constants";

export const swaggerTransformer = (data) => {
  const paths = data.paths;
  const schemas = data.components.schemas;
  const urls = Object.keys(paths);
  const schemaLoadedDate = DateTime.now(); // set this for all examples to have the same date time

  const getEndpointInfo = (url, paths, schemas) => {
    const swaggerData = paths[url];
    const method = Object.keys(swaggerData)[0];
    const params = swaggerData[method].parameters;

    const security = swaggerData[method]?.security
      ? Object.keys(swaggerData[method].security[0])[0]
      : SECURITY.BEARER_TOKEN;

    const hasRequestBody = swaggerData[method].requestBody || null;

    const schemaPath =
      swaggerData[method].requestBody?.content["application/json"].schema ||
      null;
    const schemaName = schemaPath ? schemaPath["$ref"].split("/").pop() : null;

    // if there is no request body its a GET which means we dont want an accept header since there is no body
    const requestContentTypes =
      hasRequestBody && Object.keys(hasRequestBody?.content);

    // this should always be a minimum of json or if we have a label api extra formats
    const responseAcceptTypes = swaggerData[method]?.responses[HTTP_STATUS.OK]
      .content
      ? Object.keys(swaggerData[method]?.responses[HTTP_STATUS.OK]?.content)
      : [DEFAULT_CONTENT_TYPES];

    const flatternResponses = (res) => {
      const codes = Object.keys(res);

      return codes.map((code) => {
        const hasExampleResponse = res[code]?.content || null;
        if (hasExampleResponse) {
          const exampleResponses = res[code].content;
          for (const response in exampleResponses) {
            if (exampleResponses[response]?.schema?.hasOwnProperty("$ref")) {
              const schemaName = exampleResponses[response]?.schema?.$ref
                .split("/")
                .pop();
              if (schemaName) {
                exampleResponses[response].schemaData = schemas[schemaName];
              }
            } else if (
              exampleResponses[response]?.schema?.hasOwnProperty("oneOf")
            ) {
              const schemaData = {};
              for (const name in exampleResponses[response]?.schema?.oneOf) {
                const schemaName = exampleResponses[response]?.schema?.oneOf[
                  name
                ].$ref
                  .split("/")
                  .pop();
                schemaData[schemaName] = schemas[schemaName];
              }
              exampleResponses[response].schemaData = schemaData;
            }
          }
        }

        return {
          responseCode: code,
          description: res[code].description,
          mediaTypes: hasExampleResponse ? Object.keys(hasExampleResponse) : [],
          exampleResponses: hasExampleResponse ? res[code]?.content : {},
        };
      });
    };

    const responses = flatternResponses(swaggerData[method].responses);

    return {
      urlPath: url,
      method,
      summary: swaggerData[method].summary,
      params,
      description: swaggerData[method].description || null,
      responses,
      requestBodyExample:
        transformTemplateObj(hasRequestBody?.content, schemaLoadedDate) || null,
      schema: schemaName
        ? transformTemplateObj(schemas[schemaName], schemaLoadedDate)
        : null,
      requestContentTypes,
      responseAcceptTypes,
      authMethod: security,
    };
  };

  return urls.map((url) => getEndpointInfo(url, paths, schemas));
};

export const swaggerAvailableAuth = (doc) => {
  const { securitySchemes } = doc.components;

  if (!securitySchemes) {
    return [];
  }

  const authValues = Object.values(securitySchemes);
  const authKeys = Object.keys(securitySchemes);

  return authValues.map((value, index) => ({
    ...value,
    name: authKeys[index],
  }));
};

const TEMPLATE_TYPES = {
  DATE: "date",
};

const transformDateTemplate = (format) => {
  const dateTime = DateTime.now().setZone("Europe/London");
  return dateTime.toFormat(format);
};

const parseTemplateString = (templateStr) =>
  templateStr.slice(2, -2).trim()?.split(" ");

export const transformTemplateStrings = (value) => {
  if (!value || typeof value !== "string" || !value.startsWith("{{")) {
    return value;
  }
  const [method, params] = parseTemplateString(value);
  if (method === TEMPLATE_TYPES.DATE) {
    return transformDateTemplate(params);
  }

  return value;
};

function replaceDateStrings(jsonObject, dateOverride) {
  const dateRegex = /\{\{date\s([^"]+)\}\}/g;

  function replaceMatch(match, format) {
    const date = dateOverride ? dateOverride : DateTime.now();
    const dateTime = date.setZone("Europe/London");
    return dateTime.toFormat(format);
  }

  function recursivelyReplaceDates(obj) {
    if (typeof obj === "string") {
      return obj.replace(dateRegex, replaceMatch);
    } else if (Array.isArray(obj)) {
      return obj.map(recursivelyReplaceDates);
    } else if (obj !== null && typeof obj === "object") {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key === ".example") {
            obj[key] = recursivelyReplaceDates(obj[key]);
          } else {
            obj[key] = recursivelyReplaceDates(obj[key]);
          }
        }
      }
    }
    return obj;
  }

  return recursivelyReplaceDates(jsonObject);
}

export const transformTemplateObj = (obj, dateOverride) => {
  if (!obj) {
    return obj;
  }
  return replaceDateStrings(obj, dateOverride);
};
