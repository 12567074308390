import { useSideNav } from "../../context/StateNavContext";

const HamburgerIcon = () => {
  const { sideNavVis, toggleSideNav } = useSideNav();

  const handleClick = () => {
    toggleSideNav();
  };

  return (
    <button className="mr-3 lg:hidden block" onClick={handleClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 256 256"
        className="fill-black dark:fill-white"
      >
        {sideNavVis ? (
          <path d="M204.24,195.76a6,6,0,1,1-8.48,8.48L128,136.49,60.24,204.24a6,6,0,0,1-8.48-8.48L119.51,128,51.76,60.24a6,6,0,0,1,8.48-8.48L128,119.51l67.76-67.75a6,6,0,0,1,8.48,8.48L136.49,128Z"></path>
        ) : (
          <path d="M222,128a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,128ZM40,70H216a6,6,0,0,0,0-12H40a6,6,0,0,0,0,12ZM216,186H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Z"></path>
        )}
      </svg>
    </button>
  );
};

export default HamburgerIcon;
