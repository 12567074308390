import PropTypes from "prop-types";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/prism-async";

import { CopyToClipboard } from "../../Actionable";

const CodeBlock = ({
  language,
  value,
  inline,
  withMargin = true,
  maxHeight = "max-h-[500px]",
}) => {
  return !inline ? (
    <div className="relative grid">
      <CopyToClipboard text={value} />
      <SyntaxHighlighter
        language={language}
        PreTag={"pre"}
        useInlineStyles={false}
        className={`p-[16px] bg-[#0000000d] dark:!bg-[#2B2B2B] overflow-x-auto overflow-y-auto ${maxHeight} flex`}
        codeTagProps={{
          className: "text-[#6F238A] dark:text-[#b791c4] dpd-text-code",
        }}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  ) : (
    <code
      language={language}
      className="px-[4px] text-[#6F238A] dark:text-[#b791c4] bg-[#0000000d] dark:!bg-[#2B2B2B]"
    >
      {value}
    </code>
  );
};
export default CodeBlock;

CodeBlock.propTypes = {
  language: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  inline: PropTypes.bool,
  withMargin: PropTypes.bool,
  maxHeight: PropTypes.string,
};
