import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { SECURITY } from "../../constants";
import { DATA_TEST } from "../../constants/testing/dataTest";

import BasicAuth from "./BasicAuth";
import BearerToken from "./BearerToken";
import BearerTokenRefresh from "./BearerTokenRefresh";

const AuthModal = ({
  isShowing,
  hide,
  setAuthToken,
  isAuthed,
  isBasicAuthed,
  setBasicAuth,
  setAuthTokenRefresh,
  isRefreshAuthed,
  page,
  availableAuth,
}) => {
  const [bearerToken, setBearerToken] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [bearerTokenRefresh, setBearerTokenRefresh] = useState("");

  const authComponents = [
    {
      type: SECURITY.BASIC_AUTH,
      component: (
        <BasicAuth
          key={1}
          hide={hide}
          setPassword={setPassword}
          setUsername={setUsername}
          username={username}
          password={password}
          setBasicAuth={setBasicAuth}
          isBasicAuthed={isBasicAuthed}
          authType={SECURITY.BASIC_AUTH}
          details={availableAuth}
        />
      ),
    },
    {
      type: SECURITY.BEARER_TOKEN,
      component: (
        <BearerToken
          key={2}
          hide={hide}
          setAuthToken={setAuthToken}
          setInput={setBearerToken}
          input={bearerToken}
          isAuthed={isAuthed}
          authType={SECURITY.BEARER_TOKEN}
          details={availableAuth}
        />
      ),
    },
    {
      type: SECURITY.BEARER_REFRESH,
      component: (
        <BearerTokenRefresh
          key={3}
          hide={hide}
          setAuthTokenRefresh={setAuthTokenRefresh}
          setInput={setBearerTokenRefresh}
          input={bearerTokenRefresh}
          isRefreshAuthed={isRefreshAuthed}
          authType={SECURITY.BEARER_REFRESH}
          details={availableAuth}
        />
      ),
    },
  ];

  useEffect(() => {
    if (isShowing) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isShowing]);

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="fixed top-0 left-0 z-40 w-screen h-screen bg-black opacity-50" />
          <div
            className="fixed top-0 left-0 z-50 w-full h-full overflow-x-hidden overflow-y-auto"
            aria-modal
            aria-hidden
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setBearerToken("");
                setUsername("");
                setPassword("");
                setBearerTokenRefresh("");
                hide();
              }
            }}
            tabIndex={-1}
            role="dialog"
          >
            <div
              className="z-[70] dark:bg-dark-bg bg-white relative max-w-[500px] min-w-[250px] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded"
              data-test={DATA_TEST.AUTH_MODAL}
            >
              <div className="flex justify-between pt-[12px] pl-[20px] pr-[10px] pb-[6px] dpd-border-bottom">
                <h3 className="dpd-sub-heading">Available authorisations</h3>
                <button
                  onClick={() => {
                    setBearerToken("");
                    setUsername("");
                    setPassword("");
                    setBearerTokenRefresh("");
                    hide();
                  }}
                >
                  <span className="text-[25px] leading-4" aria-hidden="true">
                    &times;
                  </span>
                </button>
              </div>
              {availableAuth.map((auth) => {
                const filteredAuths = authComponents.filter(
                  ({ type, component }) => {
                    return type === auth.name ? component : false;
                  }
                );

                return filteredAuths.map(({ component }) => component);
              })}
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default AuthModal;

AuthModal.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  setAuthToken: PropTypes.func,
  isAuthed: PropTypes.string,
  isBasicAuthed: PropTypes.string,
  setBasicAuth: PropTypes.func,
  setAuthTokenRefresh: PropTypes.func,
  isRefreshAuthed: PropTypes.string,
  page: PropTypes.string,
  availableAuth: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
