import { useSideNav } from "../../context/StateNavContext";

const CloseNavBtn = ({}) => {
  const { sideNavVis, toggleSideNav } = useSideNav();

  const closeNav = () => {
    toggleSideNav();
  };

  return (
    <>
      {sideNavVis && (
        <div
          onClick={closeNav}
          className="bg-grey-out-of-focus fixed inset-0 z-30 w-screen h-screen"
        />
      )}
    </>
  );
};

export default CloseNavBtn;
