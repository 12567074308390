const { REACT_APP_CACHEBUSTER } = process.env;

const getCachebuster = () => {
  return REACT_APP_CACHEBUSTER || new Date();
};
export const withCachebuster = (path) => {
  if (path.includes("?")) {
    return `${path}&v=${getCachebuster()}`;
  }
  return `${path}?v=${getCachebuster()}`;
};
