import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import { DATA_TEST } from "../../constants/testing/dataTest";

import { Button } from "../Actionable/";
import { AuthMarkdownComponents } from "../Typography/Markdown";

const bearerTokenRefresh = ({
  setAuthTokenRefresh,
  setInput,
  hide,
  input,
  isRefreshAuthed,
  authType,
  details,
}) => {
  const { name, description, type, scheme } = details.filter(
    ({ name }) => authType === name
  )[0];

  return (
    <div className="p-[20px] pt-[20px] dpd-text">
      <span className="text-[#6F238A] dark:text-[#b791c4]">{name}</span>
      {` (${type}, ${scheme})`}
      <div className="markdown">
        <ReactMarkdown
          children={description}
          components={AuthMarkdownComponents}
        />
      </div>
      {!isRefreshAuthed && (
        <input
          className="min-w-[230px] py-[8px] px-[10px] my-[10px] dpd-border-table dark:bg-[#272729]"
          placeholder="Bearer refresh token"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          data-test={DATA_TEST.REFRESH_TOKEN}
        />
      )}
      <div className="flex justify-center mt-[15px] gap-[10px]">
        {!isRefreshAuthed ? (
          <Button
            styleVariant={"greenOutline"}
            onClick={() => setAuthTokenRefresh(input)}
            data-test={DATA_TEST.AUTH_AUTHORISE}
          >
            Authorise
          </Button>
        ) : (
          <Button
            styleVariant={"grayOutline"}
            onClick={() => setAuthTokenRefresh("")}
          >
            Log out
          </Button>
        )}
        <Button
          styleVariant={"grayOutline"}
          onClick={() => {
            setInput("");
            hide();
          }}
          data-test={DATA_TEST.AUTH_CLOSE}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default bearerTokenRefresh;

bearerTokenRefresh.propTypes = {
  setAuthTokenRefresh: PropTypes.func,
  setInput: PropTypes.func,
  hide: PropTypes.func,
  input: PropTypes.string,
  isRefreshAuthed: PropTypes.string,
  authType: PropTypes.string,
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
