import React, { createContext, useContext, useState } from "react";

const SideNavContext = createContext();

export const useSideNav = () => {
  return useContext(SideNavContext);
};

export const SideNavProvider = ({ children }) => {
  const [sideNavVis, setSideNavVis] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);

  const toggleSideNav = () => {
    setSideNavVis((prevSideNavVis) => !prevSideNavVis);
  };

  return (
    <SideNavContext.Provider
      value={{ sideNavVis, toggleSideNav, recentSearches, setRecentSearches }}
    >
      {children}
    </SideNavContext.Provider>
  );
};
