import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Link = ({ children, to, showUnderline = true }) => {
  const style = `hover:cursor-pointer ${showUnderline ? "underline" : ""}`;
  if (!to.includes("/")) {
    //this means its on the same page lets scroll to it...
    return (
      <ScrollLink to={to} className={style} smooth>
        {children}
      </ScrollLink>
    );
  }

  //default nav to another page if starts with http go to new tab (external)
  return (
    <RouterLink
      target={to.startsWith("http") ? "_blank" : "_self"}
      to={to}
      className={style}
    >
      {children}
    </RouterLink>
  );
};

export default Link;
