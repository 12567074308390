import parse from "@bany/curl-to-json";
import React from "react";

import { getAuthHeaderStringAuthMethod } from "./security";
import { HEADERS, METHODS } from "../constants";

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const stringify = (str) => JSON.stringify(str, null, 2);

export const getPageTitle = (pathname) => {
  const path = pathname.split("/");
  return path[1];
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const joinWithHyphen = (str) => str.toLowerCase().split(" ").join("-");

const addHeaderToCurlIfNotExists = (
  headerName,
  headerValue,
  curlCommand,
  lastHeader = false
) => {
  if (!curlCommand.includes(`-H '${headerName}:`)) {
    curlCommand += ` -H '${headerName}: ${headerValue}' ${lastHeader ? "" : "\\\n"}`;
  }

  return curlCommand;
};

export const objectToCurlCommand = (obj) => {
  const queryParams = [];
  let params = "";
  let urlPath = obj.urlPath;
  const headers = [];

  let curlCommand = `curl -X '${obj.method.toUpperCase()}' \\\n`;

  if (urlPath.includes("{")) {
    obj.editParams.forEach((param) => {
      if (param.in === "path" && param.name && param.value) {
        const paramPattern = `{${param.name}}`;
        urlPath = urlPath.replace(paramPattern, param.value);
      }
    });
  }

  if (obj.editParams && Array.isArray(obj.editParams)) {
    obj.editParams.forEach((param) => {
      if (param.in === "header" && param.name && param.value) {
        headers.push({ name: param.name, value: param.value });
        params += ` -H '${param.name}: ${param.value}' \\\n`;
      } else if (param.in === "query" && param.name && param.value) {
        if (param.schema.type === "array") {
          const paramArray = param.value.split(",");
          paramArray.forEach((val) => {
            queryParams.push(`${param.name}=${encodeURIComponent(val)}`);
          });
        } else {
          queryParams.push(`${param.name}=${encodeURIComponent(param.value)}`);
        }
      }
    });
  }

  if (queryParams.length > 0) {
    const queryString = queryParams.join("&");
    urlPath = `${process.env.REACT_APP_SERVICE_URL}${urlPath}?${queryString}`;
  } else {
    urlPath = `${process.env.REACT_APP_SERVICE_URL}${urlPath}`;
  }

  curlCommand += ` '${urlPath}' \\\n`;

  if (obj?.requestContentType) {
    curlCommand = addHeaderToCurlIfNotExists(
      HEADERS["CONTENT-TYPE"],
      obj.requestContentType,
      curlCommand
    );
  }

  if (obj?.responseAcceptType) {
    curlCommand = addHeaderToCurlIfNotExists(
      HEADERS.ACCEPT,
      obj.responseAcceptType,
      curlCommand
    );
  }

  curlCommand += params;

  if (obj?.authMethod) {
    const bearerHeader = getAuthHeaderStringAuthMethod(
      obj?.authMethod,
      obj?.basicAuth,
      obj?.authToken,
      obj?.authTokenRefresh
    );
    if (bearerHeader) {
      curlCommand = addHeaderToCurlIfNotExists(
        HEADERS.AUTHORIZATION,
        bearerHeader,
        curlCommand,
        true
      );
    }
  }

  if (obj.editBody) {
    curlCommand += `\\\n -d '${obj.editBody}'`;
  }

  return { curlCommand, url: urlPath, headers };
};

export const formatOverviewText = (text) => (
  <>
    {capitalizeFirstLetter(text)}
    <br />
    Overview
  </>
);

export const selectGettingStartedImg = (img, theme) => {
  const imageName = img.toLowerCase();
  return theme === "dark"
    ? `/images/gettingStarted/dark${capitalizeFirstLetter(imageName)}.png`
    : `/images/gettingStarted/${imageName}.png`;
};

export const curlToJsonRequest = (curlCommand) => {
  return parse(curlCommand);
};

export const jsonToHttp = (jsonData) => {
  const { url, data, header, method, params } = jsonData;

  let httpCommand = method + " " + url;

  if (params) {
    httpCommand += `?`;
    for (const prop in params) {
      httpCommand += `${prop}=${params[prop]}&`;
    }
    httpCommand = httpCommand.slice(0, -1);
  }

  httpCommand += ` HTTP/1.1 \n\n`;
  for (const prop in header) {
    httpCommand += `${prop.charAt(0).toUpperCase() + prop.slice(1)}: ${
      header[prop]
    } \n`;
  }

  if (data) {
    httpCommand += `\n`;
    httpCommand += `${JSON.stringify(data, null, 2)}`;
  }

  return httpCommand;
};

export const jsonToPython = (jsonData) => {
  const { url, data, header, method, params } = jsonData;

  let paramString = `?`;
  if (params) {
    for (const prop in params) {
      paramString += `${prop}=${params[prop]}&`;
    }
    paramString = paramString.slice(0, -1);
  }

  let pythonCommand = `# See instructions for installing Requests module for Python\n# https://requests.readthedocs.io/en/master/user/install/#install\n\n`;

  pythonCommand += `import requests\n\n`;
  pythonCommand += `def execute():\n`;
  pythonCommand += `requestUrl = "${url}${params ? paramString : ""}"\n`;
  data && (pythonCommand += `requestBody = ${JSON.stringify(data, null, 2)}\n`);
  pythonCommand += `requestHeaders = {\n`;
  for (const prop in header) {
    pythonCommand += `\t"${prop.charAt(0).toUpperCase() + prop.slice(1)}": "${
      header[prop]
    }",\n`;
  }
  pythonCommand += `}\n\n`;

  pythonCommand += `requests.${method.toLowerCase()}(requestUrl, headers=requestHeaders, json=requestBody)\n\n`;
  pythonCommand += `print(response.text)\n\n`;
  pythonCommand += `if __name__ == "__main__":\n`;
  pythonCommand += `\texecute()`;

  return pythonCommand;
};

export const jsonToNode = (jsonData) => {
  const { url, data, header, method, params } = jsonData;

  let paramString = `?`;
  if (params) {
    for (const prop in params) {
      paramString += `${prop}=${params[prop]}&`;
    }
    paramString = paramString.slice(0, -1);
  }

  let nodeCommand = `// See instructions for installing Request module for NodeJS\n// https://www.npmjs.com/package/request\n\n`;

  nodeCommand += `const request = require('request');\n\n`;
  nodeCommand += `function execute() {\n`;
  nodeCommand += `const options = {\n`;
  nodeCommand += `"url": "${url}${params ? paramString : ""}",\n`;
  nodeCommand += `"method": "${method}",\n`;
  data && (nodeCommand += `"json" : ${JSON.stringify(data, null, 2)},\n`);
  nodeCommand += `"headers":{\n`;
  for (const prop in header) {
    nodeCommand += `\t"${prop.charAt(0).toUpperCase() + prop.slice(1)}": "${
      header[prop]
    }",\n`;
  }
  nodeCommand += `}\n};\n\n`;

  nodeCommand += `request(options, function (err, res, body) {\n\tif (err) {\n\t\tconsole.error(err);\n\t}\n\telse {\n\t\tconsole.log(body);\n\t}\n});\n}\n\n`;
  nodeCommand += `execute();`;

  return nodeCommand;
};

const convertToPhp = (data, level = 1) => {
  let body = "";
  for (const prop in data) {
    const indent = "\t".repeat(level);
    if (typeof data[prop] === "object") {
      body += `${indent}'${prop}' => array(\n`;
      body += convertToPhp(data[prop], level + 1);
      body += `${indent}),\n`;
    } else {
      body += `${indent}'${prop}' => '${data[prop]}',\n`;
    }
  }
  return body;
};

export const jsonToPhp = (jsonData) => {
  const { url, data, header, method, params } = jsonData;

  let paramString = `?`;
  if (params) {
    for (const prop in params) {
      paramString += `${prop}=${params[prop]}&`;
    }
    paramString = paramString.slice(0, -1);
  }

  let phpCommand = `<?php\n\n/**\n* See instructions for installing curl-php library locally\n* http://php.net/manual/en/book.curl.php\n*/\n\n`;

  phpCommand += `$url = '${url}${params ? paramString : ""}';\n`;
  phpCommand += `$ch = curl_init();\n`;
  phpCommand += `curl_setopt($ch, CURLOPT_URL, $url);\n`;
  phpCommand += `curl_setopt($ch, CURLOPT_CUSTOMREQUEST, '${method}');\n`;
  phpCommand += `curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);\n`;
  phpCommand += `curl_setopt($ch, CURLOPT_HTTPHEADER, array(\n`;
  for (const prop in header) {
    phpCommand += `\t'${prop.charAt(0).toUpperCase() + prop.slice(1)}: ${
      header[prop]
    }',\n`;
  }
  phpCommand += `));\n`;
  data &&
    (phpCommand += `$bodyArray = array(\n${convertToPhp(
      data
    )});\n$body = json_encode($bodyArray);\n`);

  phpCommand += `curl_setopt($ch, CURLOPT_POSTFIELDS, $body);\n$result = curl_exec($ch);\nif ($result === false) {\n\techo curl_error($ch);\n}\nelse {\n\techo $result;\n}\n\n?>`;

  return phpCommand;
};

export const jsonToJava = (jsonData) => {
  const { url, data, header, method, params } = jsonData;

  let paramString = `?`;
  if (params) {
    for (const prop in params) {
      paramString += `${prop}=${params[prop]}&`;
    }
    paramString = paramString.slice(0, -1);
  }

  let javaString = `import java.io.BufferedReader;\nimport java.io.InputStream;\nimport java.io.InputStreamReader;\nimport java.io.IOException;\nimport java.io.OutputStreamWriter;\nimport java.net.HttpURLConnection;\nimport java.net.URL;\nimport java.util.stream.Collectors;\n\n`;
  javaString += `public class ApiExample {\n\n`;
  javaString += `\tpublic static String execute() throws IOException {\n`;
  javaString += `\t\tURL url = new URL("${url}${
    params ? paramString : ""
  }");\n`;
  javaString += `\t\tHttpURLConnection connection = (HttpURLConnection) url.openConnection();\n`;
  javaString += `\t\tconnection.setRequestMethod("${method}");\n`;
  for (const prop in header) {
    javaString += `\t\tconnection.setRequestProperty("${
      prop.charAt(0).toUpperCase() + prop.slice(1)
    }", "${header[prop]}");\n`;
  }
  javaString += `\t\tconnection.setDoOutput(true);\n`;
  data &&
    (javaString += `\t\tOutputStreamWriter writer = new OutputStreamWriter(connection.getOutputStream());\n\t\tString body = "${JSON.stringify(
      data
    ).replace(
      /["']/g,
      "\\$&"
    )}";\n\t\twriter.write(body);\n\t\twriter.flush();\n`);
  javaString += `\t\ttry (BufferedReader r = new BufferedReader(new InputStreamReader(connection.getInputStream()))) {\n`;
  javaString += `\t\t\treturn r.lines().collect(Collectors.joining("\\n"));\n`;
  javaString += `\t\t}\n\t}\n\n`;
  javaString += `\tpublic static void main(String[] args) throws IOException {\n\t\tSystem.out.println(execute());\n\t}\n\n}`;

  return javaString;
};

export const jsonToCSharp = (jsonData) => {
  const { url, data, header, method, params } = jsonData;

  let paramString = `?`;
  if (params) {
    for (const prop in params) {
      paramString += `${prop}=${params[prop]}&`;
    }
    paramString = paramString.slice(0, -1);
  }

  let cSharpString = `using System.Net.Http;\nusing System.Net.Http.Headers;\n\n`;
  cSharpString += `HttpClientHandler handler = new HttpClientHandler();\nhandler.AutomaticDecompression = System.Net.DecompressionMethods.All;\n\n`;
  cSharpString += `HttpClient client = new HttpClient(handler);\n\n`;
  cSharpString += `HttpRequestMessage request = new HttpRequestMessage(HttpMethod.${
    method.charAt(0) + method.slice(1).toLowerCase()
  }, "${url}${params ? paramString : ""}");\n\n`;
  for (const prop in header) {
    cSharpString += `request.Headers.Add("${
      prop.charAt(0).toUpperCase() + prop.slice(1)
    }", "${header[prop]}");\n`;
  }
  cSharpString += `\n`;

  data &&
    (cSharpString += `request.Content = new StringContent("${JSON.stringify(
      data
    ).replace(
      /["']/g,
      "\\$&"
    )}");\nrequest.Content.Headers.ContentType = new MediaTypeHeaderValue("${
      header["Content-Type"]
    }");\n\n`);

  cSharpString += `HttpResponseMessage response = await client.SendAsync(request);\n`;
  cSharpString += `response.EnsureSuccessStatusCode();\n`;
  cSharpString += `string responseBody = await response.Content.ReadAsStringAsync();`;

  return cSharpString;
};

export const jsonToJavaScript = (jsonData) => {
  const { url, data, header, method, params } = jsonData;

  let paramString = `?`;
  if (params) {
    for (const prop in params) {
      paramString += `${prop}=${params[prop]}&`;
    }
    paramString = paramString.slice(0, -1);
  }

  let javaScriptString = `<button onclick="execute()">Execute</button>\n\n<script>\n\n// WARNING: fetch is not supported in IE, so it may need a polyfill\n\n`;

  javaScriptString += `function execute() {\n`;
  javaScriptString += `const url = "${url}${params ? paramString : ""}";\n`;
  javaScriptString += `const options = {\n`;
  javaScriptString += `\tmethod: "${method}",\n`;
  javaScriptString += `\theaders: {\n`;
  for (const prop in header) {
    javaScriptString += `\t\t"${
      prop.charAt(0).toUpperCase() + prop.slice(1)
    }": "${header[prop]}",\n`;
  }
  javaScriptString += `\t},\n`;
  data &&
    (javaScriptString += `\tbody: JSON.stringify(${JSON.stringify(data)}),\n`);
  javaScriptString += `};\n`;
  javaScriptString += `fetch(url, options).then(\n`;
  javaScriptString += `\tresponse => {\n`;
  javaScriptString += `\t\tif (response.ok) {\n`;
  javaScriptString += `\t\t\treturn response.text();\n`;
  javaScriptString += `\t\t}\n`;
  javaScriptString += `\t\treturn response.text().then(err => {\n`;
  javaScriptString += `\t\t\treturn Promise.reject({\n`;
  javaScriptString += `\t\t\t\tstatus: response.status,\n`;
  javaScriptString += `\t\t\t\tstatusText: response.statusText,\n`;
  javaScriptString += `\t\t\t\terrorMessage: err,\n`;
  javaScriptString += `\t\t\t});\n`;
  javaScriptString += `\t\t});\n`;
  javaScriptString += `\t}).then(data => {\n`;
  javaScriptString += `\t\tconsole.log(data);\n`;
  javaScriptString += `\t}).catch(err => {\n`;
  javaScriptString += `\t\tconsole.error(err);\n`;
  javaScriptString += `\t});\n}\n\n`;
  javaScriptString += `</script>`;

  return javaScriptString;
};

export const hasValue = (str) => str !== undefined && str !== "";

export const sanitisePath = (str) => {
  if (!str) {
    return str;
  }

  return str
    .toLowerCase()
    .replaceAll("/", "-") // Select Api/Sandbox path
    .replaceAll(" ", "-")
    .replaceAll(":", "-") // step-1:-registration
    .replace(/-{2,}/g, "-"); // Replace consecutive hyphens with a single hyphen for friendly urls "Request / Response Format" request---response-format => request-response-format
};

export const truncateString = function (
  str,
  truncateAt = 50,
  addEllipsis = true
) {
  if (!str || typeof str !== "string") {
    return str;
  }

  if (str.length > truncateAt && addEllipsis) {
    return str.slice(0, truncateAt - 3) + "...";
  }
  return str.slice(0, truncateAt);
};

export const methodBadge = (method) => {
  switch (method) {
    case METHODS.GET:
      return "bg-[#e5f0ff] text-[#438DED] dark:bg-[#0F2C4B] dark:text-[#E5F0FF]";
    case METHODS.POST:
      return "bg-[#E1EFE3] text-[#66b553] dark:bg-[#509E2F] dark:text-[#E1EFE3]";
    default:
      return "";
  }
};
