import PropTypes from "prop-types";

import { METHODS } from "../../constants";

const Title = ({ method, menu = false }) => {
  const methodBadge = () => {
    switch (method) {
      case METHODS.GET:
        return "bg-[#e5f0ff] text-[#438DED] dark:bg-[#0F2C4B] dark:text-[#E5F0FF]";
      case METHODS.POST:
        return "bg-[#E1EFE3] text-[#66b553] dark:bg-[#509E2F] dark:text-[#E1EFE3]";
      default:
        return "";
    }
  };

  return (
    <div
      className={`${menu ? "px-[2px] ml-1 " : "mr-[8px] p-[8px] "}dpd-nav-text ${methodBadge()} capitalize sm:break-words`}
    >
      <p className={`${menu ? "text-[10px]" : "font-medium"}`}>{method}</p>
    </div>
  );
};

export default Title;

Title.propTypes = {
  method: PropTypes.string,
};
