import PropTypes from "prop-types";

const Counter = ({ count }) => {
  return (
    <span className="z-10 flex justify-center -translate-y-[-20px] translate-x-5 max-w-[25px] max-h-[25px] rounded-full bg-red-600 p-[10%] text-[18px] mr-[16px">
      <p className="text-white text-center self-center ">{count}</p>
    </span>
  );
};

export default Counter;

Counter.propTypes = {
  count: PropTypes.number,
};
