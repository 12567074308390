import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { cva } from "class-variance-authority";
import React from "react";

import { capitalizeFirstLetter } from "../../helpers/strings";

const messageCard = cva("messageCard", {
  variants: {
    type: {
      note: [
        "rounded-lg",
        "border",
        "border-yellow-500",
        "bg-yellow-100",
        "shadow-md",
        "p-4",
        "my-[16px]",
        "text-[#684F10]",
        "dark:border-[#987319]",
        "dark:bg-[#332704]",
        "dark:text-[#FED972]",
      ],
      warning: [
        "rounded-lg",
        "border",
        "border-red-500",
        "bg-red-200",
        "shadow-md",
        "p-4",
        "my-[16px]",
        "text-[#57161D]",
        "dark:border-[#83212B]",
        "dark:bg-[#2C0B0E]",
        "dark:text-[#E88790]",
      ],
      example: [
        "rounded-[8px]",
        "border",
        "border-[#B6B6B6]",
        "bg-[#EBEBEB]",
        "shadow-[1px 2px 1px 0px rgba(0, 0, 0, 0.10)]",
        "p-4",
        "my-[16px]",
        "text-[#000000]",
        "dark:border-[#555]",
        "dark:bg-[#2C2C2C]",
        "dark:text-[#ADB5BD]",
      ],
    },
  },
  defaultVariants: {
    type: "note",
  },
});

const MessageCard = ({ messageType, message }) => {
  return (
    <div className={messageCard({ type: messageType })}>
      <div className="font-bold flex items-center">
        {messageType === "note" && (
          <InformationCircleIcon className="h-[22px] mr-1" />
        )}
        {messageType === "warning" && (
          <ExclamationTriangleIcon className="h-[22px] mr-1" />
        )}
        <span>{capitalizeFirstLetter(messageType)}</span>
      </div>
      <div className={`font-light ${messageType === "example" ? "" : "pl-7"}`}>
        {message}
      </div>
    </div>
  );
};
export default MessageCard;
