import { useMemo } from "react";

export default function useFormattedSteps(steps) {
  return useMemo(() => {
    const formattedSteps = [{ summary: "Introduction" }];
    steps.forEach((step, index) => {
      formattedSteps.push({ summary: `Step ${index + 1}: ${step.title}` });
    });
    return formattedSteps;
  }, [steps]);
}
