import React, { useState, useEffect } from "react";

export const ThemeContext = React.createContext();

function getInitialTheme() {
  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    return "dark";
  } else {
    return "light";
  }
}

export const ThemeProvider = (props) => {
  const [theme, setTheme] = useState(() => {
    const localTheme = window.localStorage.getItem("theme");
    return localTheme ? localTheme : getInitialTheme();
  });

  useEffect(() => {
    window.localStorage.setItem("theme", theme);
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};
