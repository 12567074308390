import React from "react";

import { ThemeContext } from "../../context/ThemeContext";

const DarkModeToggleIcon = () => {
  const { theme } = React.useContext(ThemeContext);

  return (
    <>
      {theme === "dark" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g id="contrast">
            <mask
              id="mask0_626_11130"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect
                id="Bounding box"
                x="24"
                y="24"
                width="24"
                height="24"
                transform="rotate(180 24 24)"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_626_11130)">
              <path
                id="contrast_2"
                d="M11.996 4C13.1021 4 14.1422 4.20989 15.1163 4.62968C16.0904 5.04948 16.9377 5.6192 17.6582 6.33883C18.3788 7.05848 18.9492 7.90472 19.3695 8.87756C19.7898 9.85041 20 10.8899 20 11.996C20 13.1021 19.7901 14.1422 19.3703 15.1163C18.9505 16.0904 18.3808 16.9377 17.6612 17.6582C16.9415 18.3788 16.0953 18.9492 15.1224 19.3695C14.1496 19.7898 13.1101 20 12.004 20C10.8979 20 9.85779 19.7901 8.88372 19.3703C7.90964 18.9505 7.06233 18.3808 6.34179 17.6612C5.62124 16.9415 5.0508 16.0953 4.63048 15.1224C4.21016 14.1496 4 13.1101 4 12.004C4 10.8979 4.20989 9.85779 4.62968 8.88373C5.04948 7.90964 5.61919 7.06232 6.33883 6.34177C7.05847 5.62124 7.90472 5.05081 8.87756 4.63048C9.85041 4.21016 10.8899 4 11.996 4ZM11.6782 4.64368C9.76245 4.73563 8.11111 5.4751 6.72414 6.86207C5.33716 8.24904 4.64368 9.96169 4.64368 12C4.64368 14.0383 5.3295 15.7433 6.70115 17.1149C8.0728 18.4866 9.7318 19.2337 11.6782 19.3563L11.6782 4.64368Z"
                fill="#ADB5BD"
              />
            </g>
          </g>
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="contrast">
            <mask
              id="mask0_626_11130"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect
                id="Bounding box"
                x="24"
                y="24"
                width="24"
                height="24"
                transform="rotate(180 24 24)"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_626_11130)">
              <path
                id="contrast_2"
                d="M11.996 4C13.1021 4 14.1422 4.20989 15.1163 4.62968C16.0904 5.04948 16.9377 5.6192 17.6582 6.33883C18.3788 7.05848 18.9492 7.90472 19.3695 8.87756C19.7898 9.85041 20 10.8899 20 11.996C20 13.1021 19.7901 14.1422 19.3703 15.1163C18.9505 16.0904 18.3808 16.9377 17.6612 17.6582C16.9415 18.3788 16.0953 18.9492 15.1224 19.3695C14.1496 19.7898 13.1101 20 12.004 20C10.8979 20 9.85779 19.7901 8.88372 19.3703C7.90964 18.9505 7.06233 18.3808 6.34179 17.6612C5.62124 16.9415 5.0508 16.0953 4.63048 15.1224C4.21016 14.1496 4 13.1101 4 12.004C4 10.8979 4.20989 9.85779 4.62968 8.88373C5.04948 7.90964 5.61919 7.06232 6.33883 6.34177C7.05847 5.62124 7.90472 5.05081 8.87756 4.63048C9.85041 4.21016 10.8899 4 11.996 4ZM11.6782 4.64368C9.76245 4.73563 8.11111 5.4751 6.72414 6.86207C5.33716 8.24904 4.64368 9.96169 4.64368 12C4.64368 14.0383 5.3295 15.7433 6.70115 17.1149C8.0728 18.4866 9.7318 19.2337 11.6782 19.3563L11.6782 4.64368Z"
                fill="#ADB5BD"
              />
            </g>
          </g>
        </svg>
      )}
    </>
  );
};

export default DarkModeToggleIcon;
