import * as Sentry from "@sentry/react";

const {
  NODE_ENV,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_RELEASE,
  REACT_APP_SENTRY_ENV,
  REACT_APP_DEBUG,
} = process.env;

const isProduction = NODE_ENV === "production";
const debug = REACT_APP_DEBUG === "true";

if (isProduction || debug) {
  Sentry.init({
    debug: debug,
    enabled: isProduction,
    release: REACT_APP_SENTRY_RELEASE,
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_SENTRY_ENV,
    ignoreErrors: [
      // contextmenu error for electron (v.0.37.8)
      "Cannot read property 'nodeType' of undefined",
      "SecurityError",
      // Random plugins/extensions
      "top.GLOBALS",
      "Can't find variable: ga",
      /(ResizeObserver loop limit exceeded)/,
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  });
}
