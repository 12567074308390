import React from "react";

import { Link } from "../../components/Actionable/";
import BodyParagraph from "../../components/Typography/BodyParagraph";

import { PAGES, PATHS } from "../";

const sections = [
  {
    summary: "Overview",
    body: (
      <>
        <BodyParagraph>
          Once you have obtained your login credentials from your account
          manager, the initial step in order to be able to utilize our web
          services is to integrate with the authentication API. This process
          enables you to obtain a token to use for authentication purposes
          against API endpoints and gain access to our various services.
        </BodyParagraph>
        <BodyParagraph>
          Please click here to refer to the{" "}
          <Link to={PATHS.AUTH_INTRODUCTION}>authentication introduction</Link>{" "}
          which will give an overview on the authentication services available
          and how to utilize.
        </BodyParagraph>
        <BodyParagraph>
          Once this has been completed this can be used to authenticate with the
          below services
        </BodyParagraph>
        <BodyParagraph>
          <ul className="list-disc list-inside">
            {Object.keys(PAGES)
              .filter((key) => key !== "AUTH")
              .map((key) => (
                <li key={key}>
                  <Link to={`/${PAGES[key]}${PATHS.INTRODUCTION}`}>
                    {PAGES[key].charAt(0).toUpperCase() + PAGES[key].slice(1)}
                  </Link>
                </li>
              ))}
          </ul>
        </BodyParagraph>
      </>
    ),
  },
];

export default sections;
