import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { METHODS } from "../../constants";
import steps from "../../constants/gettingStarted/gettingStartedSteps";
import { useSideNav } from "../../context/StateNavContext";
import { sanitisePath } from "../../helpers/strings";

import SideNavItem from "./SideNavItem";
import { MenuSearch } from "../Actionable/";

const SideNav = ({ routes, allMethods, isIntro = false }) => {
  const gettingStartedVersions = steps.map((step) => step.title);
  const { sideNavVis } = useSideNav();
  const [activeItem, setActiveItem] = useState({});
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      const page = pathname.split("/")[1];
      const version = pathname.split("/")[2]?.toUpperCase();
      setActiveItem({ page, version });
    }
  }, [pathname]);

  const getActiveVersion = (versions) => {
    const activeVersion = versions.find((version) =>
      pathname.includes(sanitisePath(version))
    );
    return activeVersion || "";
  };

  const isActive = (routeName) => {
    if (routeName.toLowerCase() === "getting-started") {
      return pathname === "/" || pathname.startsWith("/getting-started");
    }
    return routeName.toLowerCase() === activeItem.page;
  };
  const generateRoutes = () => {
    return routes.map(({ name, versions }, index) => {
      const lowerCaseName = name.toLowerCase();
      const methods = allMethods.filter(
        (method) => method.docName === lowerCaseName
      );
      const getMethods = methods
        .filter(({ method }) => method === METHODS.GET)
        .map(({ summary }) => summary);
      const postMethods = methods
        .filter(({ method }) => method === METHODS.POST)
        .map(({ summary }) => summary);

      const [latestVersion] = versions; // latest is always first in the manifest
      return (
        <SideNavItem
          key={name + latestVersion}
          name={name}
          isActive={isActive(name)}
          versions={[latestVersion]}
          activeVersion={activeItem.version}
          postMethods={postMethods}
          getMethods={getMethods}
        />
      );
    });
  };

  return (
    <div
      className={`lg:block lg:sticky top-0 absolute z-40 bg-white dark:bg-[#1B1B1D] dark:text-[#ADB5BD]; max-w-[250px] lg:min-w-[250px] min-w-[250px] dpd-text-light ${
        sideNavVis ? "block" : "hidden"
      } mr-auto dpd-border-right ${
        !isIntro ? "h-[100%] lg:h-auto" : "h-[100vh]"
      }`}
    >
      <div className="block lg:min-w-[250px] max-w-[250px] min-w-fit dpd-text-light sticky top-0">
        <MenuSearch
          allMethods={allMethods}
          gettingStartedVersions={gettingStartedVersions}
        />
        <ul>
          <SideNavItem
            name={"Getting Started"}
            isActive={isActive("getting-started")}
            versions={gettingStartedVersions.map((name) => ({ name }))}
            activeVersion={getActiveVersion(gettingStartedVersions)}
            customPath={true}
          />
          {generateRoutes()}
        </ul>
      </div>
    </div>
  );
};

export default SideNav;

SideNav.propTypes = {
  routes: PropTypes.array,
  isIntro: PropTypes.bool,
};
