import React from "react";
import { Link } from "react-router-dom";

import { capitalizeFirstLetter, sanitisePath } from "../../helpers/strings";
import { joinWithHyphen } from "../../helpers/strings";
import { navCardName } from "../../helpers/testing";

const NavCard = ({ navType, navLocation, isGettingStartedPage, baseUrl }) => {
  let href = "";
  if (isGettingStartedPage) {
    href =
      navLocation.toLowerCase() === "getting started"
        ? "/getting-started"
        : `/getting-started/${sanitisePath(navLocation)}`;
  } else if (baseUrl) {
    href = `${baseUrl}/${joinWithHyphen(navLocation.toLowerCase())}`;
  }

  const isNext = navType.toLowerCase() === "next";

  return (
    <Link
      to={href}
      className={`flex flex-col items-start w-1/2 mb-[40px] p-4 gap-1 border border-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 transition duration-300 ease-in-out dark:border-[#444950] rounded ${
        isNext ? "items-end ml-auto" : "mr-[10px]"
      }`}
      data-test={navCardName(isNext ? "next" : "prev")}
    >
      <p
        className={`text-sm font-medium leading-5 tracking-wide text-gray-600 dark:text-dark-text ${
          isNext ? "text-right" : ""
        }`}
      >
        {navType}
      </p>
      <p
        className={`text-base font-normal tracking-wide text-red-600 ${
          isNext ? "text-right" : ""
        }`}
      >
        {capitalizeFirstLetter(navLocation)}
      </p>
    </Link>
  );
};

export default NavCard;
