import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";
import remarkGfm from "remark-gfm";

import { APPLICATION_TYPE, PRINTER_TYPES } from "../../constants";
import { stringify } from "../../helpers/strings";

import { Select } from "../Actionable/";
import NestedTable from "../Swagger/NestedTable";
import JsonBlock from "../Typography/JsonBlock";
import { TableMarkdownComponents } from "../Typography/Markdown";

const ResponseTableItem = ({ response }) => {
  const [exampleTypeToShow, setExampleTypeToShow] = useState(
    APPLICATION_TYPE.JSON
  );
  const [exampleToShow, setExampleToShow] = useState("");
  const [exampleList, setExampleList] = useState([]);
  const { pathname } = useLocation();
  const formats = ["Example", "Preview", "Schema"];
  const [selectedFormat, setSelectedFormat] = useState(formats[0]);
  const [height, setHeight] = useState("0px");
  const ref = useRef();

  useEffect(() => {
    setExampleToShow("");
    setExampleTypeToShow(APPLICATION_TYPE.JSON);
    setExampleList([]);
  }, [response, pathname]);

  useEffect(() => {
    const examples = response?.exampleResponses || {};
    const exampleTypes = examples[exampleTypeToShow]?.examples || {};
    setExampleList(Object.keys(exampleTypes) || []);
  }, [response, exampleTypeToShow]);

  useEffect(() => {
    if (response.exampleResponses && exampleTypeToShow) {
      const responses =
        response.exampleResponses[exampleTypeToShow]?.examples || {};

      if (Object.keys(responses).length >= 1) {
        const defaultValue = Object.keys(responses);
        setExampleToShow(defaultValue[0]);
      }
    }
  }, [response.exampleResponses, exampleTypeToShow]);

  const getExampleRes = (exampleResponse, schemaData) => {
    if (exampleToShow) {
      return (
        <div className="my-[8px]">
          {selectedFormat === "Example" && (
            <JsonBlock
              value={
                PRINTER_TYPES.includes(exampleTypeToShow)
                  ? exampleResponse[exampleToShow]?.value
                  : stringify(exampleResponse[exampleToShow]?.value)
              }
            />
          )}
          {selectedFormat === "Preview" && (
            <iframe
              title="htmlPreview"
              srcDoc={exampleResponse[exampleToShow]?.value}
              ref={ref}
              width="100%"
              height={height}
              onLoad={() =>
                setHeight(
                  ref.current.contentWindow.document.body.scrollHeight +
                    30 +
                    "px"
                )
              }
              className="bg-white"
            />
          )}
          {selectedFormat === "Schema" ? (
            !schemaData.hasOwnProperty("type") ? (
              <NestedTable
                data={schemaData[exampleToShow.replace(/Example$/, "Error")]}
                exampleTypeToShow={exampleTypeToShow}
              />
            ) : (
              <NestedTable
                data={schemaData}
                exampleTypeToShow={exampleTypeToShow}
              />
            )
          ) : null}
        </div>
      );
    }

    if (exampleResponse || exampleResponse?.error) {
      return (
        <div className="my-[8px]">
          {selectedFormat === "Example" && (
            <JsonBlock value={stringify(exampleResponse)} />
          )}
          {selectedFormat === "Schema" ? (
            schemaData ? (
              !schemaData.hasOwnProperty("type") ? (
                <NestedTable
                  data={schemaData[exampleToShow.replace(/Example$/, "Error")]}
                  exampleTypeToShow={exampleTypeToShow}
                />
              ) : (
                <NestedTable
                  data={schemaData}
                  exampleTypeToShow={exampleTypeToShow}
                />
              )
            ) : (
              <p>No schema to show</p>
            )
          ) : null}
        </div>
      );
    }
  };

  return (
    <>
      <tr>
        <td className="dpd-border-table dpd-text-code py-[8px] text-center px-[16px]">
          {response.responseCode}
        </td>
        <td className="dpd-border-table dpd-text-light py-[8px] px-[16px]">
          <ReactMarkdown
            className="markdown-table"
            children={response.description}
            components={TableMarkdownComponents}
            remarkPlugins={[remarkGfm]}
          />
          <div className="flex justify-between">
            {response.mediaTypes.length > 0 && (
              <Select
                labelTitle={"Media Type"}
                selectList={response.mediaTypes}
                onChange={(e) => {
                  setExampleTypeToShow(e.target.value);
                  setExampleToShow("");
                  setSelectedFormat("Example");
                }}
                idOverride={`${response.responseCode}-media-type`}
              />
            )}
            {exampleList?.length > 0 && (
              <Select
                className="left"
                labelTitle={"Examples"}
                selectList={exampleList}
                onChange={(e) => setExampleToShow(e.target.value)}
                idOverride={`${response.responseCode}-example`}
              />
            )}
          </div>
          {response.exampleResponses &&
            exampleTypeToShow &&
            (response.exampleResponses[exampleTypeToShow]?.examples ||
              response.exampleResponses[exampleTypeToShow]?.example ||
              response.exampleResponses[exampleTypeToShow]?.schemaData) && (
              <>
                <div className="flex">
                  {formats
                    .filter(
                      (format) =>
                        format !== "Preview" ||
                        exampleTypeToShow === APPLICATION_TYPE.HTML
                    )
                    .map((format) => (
                      <button
                        className={`dpd-text px-[8px] py-[16px] border-b-[2px] mb-[-2px] ${
                          selectedFormat === format ? "border-[#DC0032]" : ""
                        }`}
                        onClick={() => setSelectedFormat(format)}
                        key={format}
                      >
                        {format}
                      </button>
                    ))}
                </div>
                {getExampleRes(
                  response.exampleResponses[exampleTypeToShow]?.examples ||
                    response.exampleResponses[exampleTypeToShow]?.example,
                  response.exampleResponses[exampleTypeToShow]?.schemaData
                )}
              </>
            )}
        </td>
      </tr>
    </>
  );
};

export default ResponseTableItem;

ResponseTableItem.propTypes = {
  response: PropTypes.object,
};
