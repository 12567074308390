import React, { useState } from "react";

export const TryItOutContext = React.createContext();

export const TryItOutProvider = ({ children }) => {
  const [showTryItOut, setShowTryItOut] = useState(false);

  return (
    <TryItOutContext.Provider value={{ showTryItOut, setShowTryItOut }}>
      {children}
    </TryItOutContext.Provider>
  );
};
