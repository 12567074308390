import { useState, useEffect } from "react";

import ChevronRight from "../Svgs/ChevronRight";

const ScrollButton = ({ showTryItOut }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (!entry.isIntersecting) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
    const anchor = document.getElementById("back-to-top-anchor");
    if (anchor) {
      observer.observe(anchor);
    }
    return () => {
      if (anchor) {
        observer.unobserve(anchor);
      }
    };
  }, []);

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <button
      onClick={handleClick}
      className={`fixed bottom-[50px] right-[50px] ${
        showTryItOut ? "sm:right-[55vw] md:right-[55vw] lg:right-[45vw]" : ""
      } float-right z-50 rounded-full text-white bg-[#E5444B] dark:bg-[#760C10] dark:hover:bg-[#FF1512] hover:bg-red-800 transform rotate-180 p-[4px] transition-right duration-500`}
    >
      <ChevronRight fill={"white"} />
    </button>
  );
};

export default ScrollButton;
