import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";

import { Dropdown } from "../components/Actionable/";
import { SubPageBody } from "../components/GettingStarted/SubPageBody";
import Breadcrumb from "../components/Navigation/Breadcrumb";
import NavCard from "../components/Navigation/NavCard";
import GettingStartedPageNav from "../components/Navigation/OnPageNav";
import steps from "../constants/gettingStarted/gettingStartedSteps";
import { capitalizeFirstLetter } from "../helpers/strings";
import useDropdownMenuItems from "../hooks/useMenuItemsSections";

const SubPage = ({ pageName, sections }) => {
  const menuItems = useDropdownMenuItems(sections);
  const location = useLocation();
  const showNavCards = decodeURIComponent(location.pathname).includes(
    "getting-started"
  );

  const currentIndex = steps.findIndex(
    (step) => step.title.toLowerCase() === pageName.toLowerCase()
  );

  const previousStep =
    currentIndex > 0 ? steps[currentIndex - 1].title : "getting Started";
  const nextStep =
    currentIndex < steps.length - 1 ? steps[currentIndex + 1].title : null;

  return (
    <>
      <div id="back-to-top-anchor" className="w-[100%] max-w-[1000px]">
        <div className="px-[24px]">
          <Breadcrumb paths={location.pathname} />
          <Dropdown
            buttonContent="On this page"
            menuItems={menuItems}
            buttonClassName={{ styleVariant: "default" }}
            menuClassName="lg:hidden pt-[10px]"
          />
          <p className=" mt-[12px] text-[32px] font-[370] tracking-[0.3]">
            {capitalizeFirstLetter(pageName)}
          </p>
          <SubPageBody sections={sections} />
          {showNavCards && (
            <div className="flex">
              {previousStep && (
                <NavCard
                  navType="Previous"
                  navLocation={previousStep}
                  isGettingStartedPage={true}
                />
              )}
              {nextStep && (
                <NavCard
                  navType="Next"
                  navLocation={nextStep}
                  isGettingStartedPage={true}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <GettingStartedPageNav steps={sections} />
    </>
  );
};

export default SubPage;

SubPage.propTypes = {
  pageName: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
};
