import React from "react";

import { Link } from "../../../components/Actionable/";
import ApiMethodIndicator from "../../../components/Typography/ApiMethodIndicator";
import allMethods from "../../../docs/allMethods.json";
import { sanitisePath } from "../../../helpers/strings";

const getAvailableApis = (pageName) =>
  allMethods
    .reduce((authArr, method) => {
      if (method.docName === pageName) {
        authArr.push({
          summary: method.summary,
          path: `/${pageName}/${sanitisePath(method.version)}/${sanitisePath(method.summary)}`,
          method: method.method,
        });
      }
      return authArr;
    }, [])
    .sort((a, b) =>
      a.method === "post" && b.method === "get"
        ? -1
        : a.method === "get" && b.method === "post"
          ? 1
          : 0
    );

const getAvailableEndpoints = (pageName) => {
  const availableMethods = getAvailableApis(pageName);
  return (
    <>
      {availableMethods.map((endpoint) => (
        <div key={endpoint.path}>
          <ApiMethodIndicator method={endpoint.method} />
          <Link to={endpoint.path}>{endpoint.summary}</Link>
        </div>
      ))}
    </>
  );
};

export default getAvailableEndpoints;
