import { cva } from "class-variance-authority";
import PropTypes from "prop-types";
import React from "react";

const button = cva("button", {
  variants: {
    styleVariant: {
      redSolid: [
        "gap-[6px]",
        "text-white",
        "bg-[#E5444B]",
        "dark:bg-[#760C10]",
        "dark:border-[#FF1512]",
        "dark:border",
        "dark:hover:bg-[#FF1512]",
        "hover:bg-red-800",
        "font-medium",
        "rounded",
        "px-[12px]",
        "py-[6px]",
        "mb-[32px]",
        "transition duration-300",
        "ease-in-out",
      ],
      greenSolid: [
        "gap-[6px]",
        "text-white",
        "bg-[#66B553]",
        "dark:bg-[#2D4A22]",
        "dark:border-[#8EDC7B]",
        "dark:border",
        "dark:hover:bg-[#8EDC7B]",
        "dark:hover:text-black",
        "hover:bg-green-800",
        "rounded",
        "px-[12px]",
        "py-[6px]",
        "mb-[32px]",
        "transition duration-300",
        "ease-in-out",
      ],
      redOutline: [
        "px-[8px]",
        "py-[4px]",
        "dpd-button-border",
        "text-[#e5444b]",
        "hover:text-red-800",
        "dark:hover:text-red-800",
        "dark:border-[#FF1512]",
        "dark:hover:bg-gray-800",
        "rounded",
        "hover:border-red-800",
        "hover:bg-gray-100",
        "mb-[32px]",
        "transition duration-300",
        "ease-in-out",
      ],
      grayOutline: [
        "px-[8px]",
        "py-[4px]",
        "dpd-button-border-black ",
        "text-[#414042]",
        "dark:text-[#E1E1E1]",
        "dark:border-[#E1E1E1]",
        "dark:hover:bg-gray-800",
        "hover:text-gray-700",
        "hover:border-gray-700",
        "hover:bg-gray-100",
        "font-medium",
        "mb-[32px]",
        "rounded",
        "mb-[32px]",
        "transition duration-300",
        "ease-in-out",
      ],
      greenOutline: [
        "px-[8px]",
        "py-[4px]",
        "text-[#66B553]",
        "dark:text-[#8EDC7B]",
        "dark:border-[#8EDC7B]",
        "dark:hover:bg-gray-800",
        "dark:hover:text-[#66B553]",
        "rounded",
        "dpd-button-border-green",
        "rounded hover:text-green-800",
        "hover:border-green-800",
        "hover:bg-gray-100",
        "font-medium",
        "rounded",
        "mb-[32px]",
        "transition duration-300",
        "ease-in-out",
      ],
      disabledSolid: [
        "gap-[6px]",
        "text-white",
        "bg-[#A9A9A9]",
        "dark:bg-[#696969]",
        "font-medium",
        "rounded",
        "px-[12px]",
        "py-[6px]",
        "mb-[32px]",
        "transition duration-300",
        "ease-in-out",
        "cursor-not-allowed",
      ],
    },
  },
  compoundVariants: [{ styleVariant: "redSolid" }],
  defaultVariants: {
    styleVariant: "redSolid",
  },
});

const Button = ({ className, styleVariant, ...props }) => (
  <button className={button({ styleVariant, className })} {...props} />
);

export default Button;

Button.propTypes = {
  className: PropTypes.string,
  styleVariant: PropTypes.string,
};
