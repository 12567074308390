import React from "react";

import { PAGES, PATHS } from "../../index";

import { Link } from "../../../components/Actionable/";
import SVGViewer from "../../../components/Actionable/SVGViewer";
import { JsonBlock } from "../../../components/Typography";
import BodyParagraph from "../../../components/Typography/BodyParagraph";
import MessageCard from "../../../components/Typography/MessageCard";
import { withCachebuster } from "../../../helpers/cache";
import { sanitisePath } from "../../../helpers/strings";
import { ERROR_HANDLING as AUTH_ERROR_HANDLING } from "../auth/sections";
import getAvailableEndpoints from "../helpers/page";

export const INTRODUCTION = {
  summary: "Introduction",
  body: (
    <>
      <BodyParagraph>
        Once parcels are shipped with DPD / DPD Local you may have a requirement
        to track the latest status of your shipped parcel.
      </BodyParagraph>
      <BodyParagraph>
        Our Parcel web services allow you to retrieve the latest parcel tracking
        status for supplied parcel numbers or via other supported search
        criteria that are shipped via DPD UK and DPD Local UK held accounts.
        This will give greater visibility of the shipment lifecycle; and also
        allow for retrieval of applicable image data for parcels (if available).
      </BodyParagraph>
      <BodyParagraph>
        The “Parcel tracking” API is available to allow you to request tracking
        for a parcel via our web service and is intended to allow you to
        populate latest tracking data within an internal system or display the
        latest status to the consumer/end user natively on a website, this is an
        alternative to being directed via the DPD or DPD Local website.
      </BodyParagraph>
      <BodyParagraph>
        The “Get delivery image” API is an API which allows the user to obtain
        available parcel images for the requested parcel. <br />
        Images are obtained by using both “Parcel tracking” API and “Get
        delivery image” API’s in conjunction.
      </BodyParagraph>
      <BodyParagraph>
        The imageKey and imageType strings required are returned within the
        “Parcel tracking” API, These values are then provided to the “Get
        delivery image” API to allow you to return available images returned as
        a base 64 encoded string.
      </BodyParagraph>
      <BodyParagraph>
        Explore the technical documentation for our available endpoints below:
      </BodyParagraph>
      <BodyParagraph>{getAvailableEndpoints(PAGES.PARCELS)}</BodyParagraph>
    </>
  ),
};

export const FLOW_INTEGRATIONS = {
  summary: "Integration",
  body: (
    <>
      <MessageCard
        messageType={"note"}
        message={
          <div style={{ wordWrap: "break-word" }}>
            In order to use parcel endpoints there is a prerequisite to have
            integrated with DPD UK authentication API’s in order to authenticate
            with Parcel API’s. Please refer to our{" "}
            <Link to={`/${PAGES.AUTH}${PATHS.INTRODUCTION}`}>
              auth specification
            </Link>{" "}
            for more information.
          </div>
        }
      />
      <BodyParagraph>
        Example flow to access our parcel tracking data:
      </BodyParagraph>
      <SVGViewer src={withCachebuster("/images/svg/ParcelTrackingFlow.svg")} />
      <BodyParagraph>Example flow to get a delivery image:</BodyParagraph>
      <SVGViewer
        src={withCachebuster("/images/svg/GetDeliveryImageFlow.svg")}
      />
    </>
  ),
};

export const CHOOSING_THE_CORRECT_API = {
  summary: "Choosing the correct API",
  body: (
    <>
      <BodyParagraph>
        Parcel tracking API - This is a tracking API that is used to obtain the
        latest tracking status of given parcel number/s requested or via other
        supported search criteria , this API assists with the retrieval of any
        applicable imageKey and imageType and parcelCode for use in conjunction
        with the Get image API where related parcel images are required.
      </BodyParagraph>
      <BodyParagraph>
        Get delivery image API - This is an API which allows you to obtain
        available parcel delivery images for a specific parcel, The image/s are
        returned in base64 encoded format by providing the applicable
        parcelCode, imageKey and imageType strings returned within the Parcel
        tracking API.{" "}
      </BodyParagraph>
    </>
  ),
};

export const SANDBOX_ENVIRONMENT_VS_LIVE = {
  summary: "Sandbox Environment vs Live Environment",
  body: (
    <>
      <BodyParagraph>
        The test URLs and endpoints are a copy of the live production API
        however tracking functionality via sandbox differs and is limited
        compared to the live environment. Please see description of the tracking
        sandbox API’s behaviour below for your reference.
      </BodyParagraph>
      <BodyParagraph>
        As the sandbox environment is independent of the live environment it is
        required to manage API keys independently, in order to track parcels via
        live API’s it is required to create API key for the live environment as
        sandbox and live keys are not interchangeable
      </BodyParagraph>
      <BodyParagraph.Header>Parcel tracking API</BodyParagraph.Header>
      <BodyParagraph>
        The Parcel tracking API sandbox will only allow the example tracking
        JSON payload/s provided within the portal to be sent and accepted to
        demonstrate a successful request response.
      </BodyParagraph>
      <MessageCard
        messageType="note"
        message="Please note any amendment to this example payload when sent will return an error similar to below, please note this error will not be returned within the live environment."
      />
      <JsonBlock
        value={JSON.stringify(
          {
            error: [
              {
                code: 101,
                links: {
                  self: "/v1/customer/parcel/tracking",
                },
                status: 400,
                fieldName: "",
                fieldPath: "",
                displayType: "",
                rule: "",
                timestamp: 1730718676008,
                type: "Application",
                message:
                  "This is a sample error, please use the parameter examples provided on the portal to get a valid response",
                id: "3d6b419454dd406e9688a025807039d8",
              },
            ],
          },
          null,
          2
        )}
      />
      <BodyParagraph>
        The successful response will return a fixed tracking response with a
        dummy parcel event data.
      </BodyParagraph>
      <BodyParagraph.Header>Get delivery image API</BodyParagraph.Header>
      <BodyParagraph>
        The Get delivery image API sandbox will only allow the provided example
        API request presented within the portal to be sent and accepted to
        return a successful request response.
      </BodyParagraph>
      <MessageCard
        messageType="note"
        message="Any other imageKey value passed other than the example value given, this will present an error similar to the error message provided above."
      />
    </>
  ),
};

export const SANDBOX_TEST_PARCEL_TRACKING = {
  summary: "Sandbox Test Parcel Tracking",
  body: (
    <>
      <BodyParagraph>
        In order to test via sandbox, specific parcel tracking number/s are
        provided for use with the sandbox tracking API, This will allow you to
        test connectivity, ensure any requests sent are accurate and retrieve
        successful response from the Sandbox API.
      </BodyParagraph>
      <MessageCard
        messageType="note"
        message="The sandbox Parcel tracking API will not be able to return live tracking status updates for any shipped parcels."
      />
      <BodyParagraph>
        Parcels will have a selection of common events so you are able to see
        parcel examples within different statuses with the delivery lifecycle.
      </BodyParagraph>
      <BodyParagraph>
        These may include the following parcel tracking events such as customer
        data received, Out for delivery and delivered for example.
      </BodyParagraph>
    </>
  ),
};

export const RATE_LIMITING = {
  summary: "Rate Limiting",
  body: (
    <>
      <BodyParagraph>
        The DPD UK API sandbox features stricter rate limits than the production
        environment, this is put in place to enable fair use for all customers.
      </BodyParagraph>
    </>
  ),
};

export const GET_DEIVERY_IMAGE_API_RESPONSE_FORMAT = {
  summary: "Get delivery image API response format",
  body: (
    <>
      <BodyParagraph>
        It is possible to return available delivery images as base 64 encoded
        JPEG or PNG formats via this API. For example a commonly requested type
        of image would be a photograph taken at point of delivery (Delivery
        Image).
      </BodyParagraph>
      <BodyParagraph>
        This is achieved by providing the relevant “format” URL parameter within
        your request and this will return the relevant output.
      </BodyParagraph>
      <BodyParagraph>
        Please see examples below where the URL Parameter has been included:
      </BodyParagraph>
      <BodyParagraph>
        {process.env.REACT_APP_SERVICE_URL}
        /v1/customer/parcel/15501999987654*18635/image?imageKey=0069*12345*214*1234*71*001*0*1&imageType=S5&
        <b>format=png</b>
      </BodyParagraph>
      <BodyParagraph>
        {process.env.REACT_APP_SERVICE_URL}
        /v1/customer/parcel/15501999987654*18635/image?imageKey=0069*12345*214*1234*71*001*0*1&imageType=S5&
        <b>format=jpeg</b>
      </BodyParagraph>
    </>
  ),
};

export const ERROR_HANDLING = {
  summary: "Error Handling",
  body: (
    <>
      <MessageCard
        messageType="note"
        message="Parcel endpoints can return both authentication based errors and also parcel related errors, the response schema of Authentication related errors and parcel related errors differ so you will need to be able to cater for both types of error responses."
      />
      <BodyParagraph>
        To see details of authentication based errors please visit our{" "}
        <Link
          to={`/${PAGES.AUTH}${PATHS.INTRODUCTION}/#${sanitisePath(AUTH_ERROR_HANDLING.summary)}`}
        >
          Authentication specification
        </Link>{" "}
        for more information.
      </BodyParagraph>
      <BodyParagraph.Header>
        Examples of common Parcel Errors
      </BodyParagraph.Header>
      <BodyParagraph>
        Please see types of examples of error codes that can be received, these
        are categorized by code/s types.
      </BodyParagraph>
      <BodyParagraph>
        <div className="markdown-table overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr>
                <th>type</th>
                <th>code</th>
                <th>status</th>
                <th>description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>System</td>
                <td>100</td>
                <td>400</td>
                <td>System errors (e.g database error)</td>
              </tr>
              <tr>
                <td>Application</td>
                <td>101</td>
                <td>400</td>
                <td>User rule errors (e.g access to parcel apis disabled)</td>
              </tr>
              <tr>
                <td>Business</td>
                <td>102</td>
                <td>400</td>
                <td>Business rule errors</td>
              </tr>
              <tr>
                <td>FieldValidation</td>
                <td>103</td>
                <td>400</td>
                <td>Field validation (e.g max length exceeded)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BodyParagraph>
      <BodyParagraph>
        Detailed error information is provided within the message field included
        within the response to describe the issue with the request.
      </BodyParagraph>
      <BodyParagraph>
        For example : "message": "\"format\" must be one of [jpeg, png]",
      </BodyParagraph>
      <BodyParagraph>
        The fieldName and fieldpath also provides the affected fieldName where
        the issue has been detected and also the fieldPath gives the object /
        array on where the fieldName is located within the JSON request.
      </BodyParagraph>
      <BodyParagraph>"fieldName": "format"</BodyParagraph>
      <BodyParagraph>"fieldPath": "format"</BodyParagraph>
      <JsonBlock
        value={JSON.stringify(
          {
            error: [
              {
                code: 103,
                links: {
                  self: "/v1/customer/parcel/15501123124724*20765/image?format=svg&imageKey=0030*19539*707*47460*2*001*0*1&imageType=S5",
                },
                status: 400,
                fieldName: "format",
                fieldPath: "format",
                displayType: "",
                rule: "",
                timestamp: 1707239329794,
                type: "FieldValidation",
                message: '"format" must be one of [jpeg, png]',
                id: "b57e7b9dd0449ef09fc6759fc7390eb0",
              },
            ],
          },
          null,
          2
        )}
      />

      <BodyParagraph>
        <ul>
          <li>
            <b>System Errors (code 100):</b>
          </li>
          <ul className={"list-disc"}>
            <li>Using the wrong ID when performing actions on a resource</li>
          </ul>
          <br />
          <li>
            <b>Application Errors (code 101):</b>
          </li>
          <ul className={"list-disc"}>
            <li>
              User role specific errors (e.g disabling creation of parcel apis
              via the customer portal)
            </li>
          </ul>
          <br />
          <li>
            <b>Business Errors (code 102):</b>
          </li>
          <ul className={"list-disc"}>
            <li>
              This account is on-stop - please contact your account manager if
              this occurs
            </li>
          </ul>
          <br />
          <li>
            <b>Field Validation Errors (code 103):</b>
          </li>
          <ul className={"list-disc"}>
            <li>Missing mandatory fields</li>
            <li>Min/Max length exceeded</li>
            <li>Min/Max value exceeded</li>
            <li>Invalid format or type</li>
          </ul>
        </ul>
        <br />
      </BodyParagraph>
    </>
  ),
};
