import { joinWithHyphen, sanitisePath } from "./strings";
import { REGEX } from "../constants";

const NAV_ITEM_SUFFIX = "-nav-item";
const NAV_CHILD_ITEM_SUFFIX = "-nav-child-item";
const NAV_ITEM_CHEVRON_SUFFIX = "-nav-chevron-item";
const PAGE_NAV_ITEM_PREFIX = "on-page-nav-";

export const getNavItemName = (name) => joinWithHyphen(name) + NAV_ITEM_SUFFIX;

export const getNavItemChevronName = (name) =>
  joinWithHyphen(name) + NAV_ITEM_CHEVRON_SUFFIX;

export const getNavItemChildName = (parentName, childName) =>
  joinWithHyphen(parentName) +
  "-" +
  joinWithHyphen(sanitisePath(childName)) +
  NAV_CHILD_ITEM_SUFFIX;

export const getNavItemGrandChildName = (
  parentName,
  childName,
  grandChildName
) =>
  joinWithHyphen(parentName) +
  "-" +
  joinWithHyphen(childName) +
  "-" +
  joinWithHyphen(grandChildName) +
  NAV_CHILD_ITEM_SUFFIX;

export const getPagNavItemName = (pageNavItemName) =>
  PAGE_NAV_ITEM_PREFIX +
  sanitisePath(pageNavItemName)
    .replace(REGEX.BRACKETS, "")
    .replace(REGEX.NUMBERS, "");

export const navCardName = (nextOrPrev) => `nav-card-${nextOrPrev}`;
