import PropTypes from "prop-types";
import { Link } from "react-scroll";

import { REGEX } from "../../constants";
import { sanitisePath } from "../../helpers/strings";
import { getPagNavItemName } from "../../helpers/testing";

const OnPageNavItem = ({ items }) => {
  return items.map((item) => {
    return (
      <Link
        activeClass="dark:bg-[#272729] bg-[#0000000d] border-l-[3px] border-[#DC0032] pl-[13px] pr-[7px]"
        className="pl-[16px] pr-[7px] py-[8px] block cursor-pointer"
        smooth
        spy={true}
        hashSpy={true}
        to={sanitisePath(item.summary)}
        key={item.summary}
        offset={-230}
        data-test={getPagNavItemName(item.summary)}
      >
        {item.summary}
      </Link>
    );
  });
};

export default OnPageNavItem;

OnPageNavItem.propTypes = {
  items: PropTypes.array,
};
