import Fuse from "fuse.js";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useSideNav } from "../../context/StateNavContext";
import docs from "../../docs/index.json";
import { sanitisePath } from "../../helpers/strings";

import { ClearIcon } from "../Svgs";
import Title from "../Typography/Title";

const MenuSearch = ({ allMethods, gettingStartedVersions }) => {
  const { pathname } = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [showRecent, setShowRecent] = useState(false);
  const { recentSearches, setRecentSearches } = useSideNav();
  const [onRecent, setOnRecent] = useState(false);

  useEffect(() => {
    setSearchValue("");
    setShowRecent(false);
    setOnRecent(false);
  }, [pathname]);

  const SearchItem = ({ method }) => {
    return (
      <Link
        to={`/${sanitisePath(method.docName)}/${
          method.docName === "Getting Started" ||
          method.summary === "Introduction"
            ? ""
            : sanitisePath(method.version) + "/"
        }${sanitisePath(method.summary)}`}
        className="w-full block h-full py-2 px-4"
      >
        <p>{method.summary}</p>
        <div className="flex items-center">
          {method?.docName && (
            <p className="text-[10px]">
              {method.docName.charAt(0).toUpperCase() + method.docName.slice(1)}
            </p>
          )}
          {method?.method && <Title method={method.method} menu />}
        </div>
      </Link>
    );
  };

  const gettingStartedMethods = gettingStartedVersions.map((ver) => {
    return {
      docName: "Getting Started",
      summary: ver,
    };
  });
  const intros = docs.apis.map((api) => {
    return { docName: api.name, summary: "Introduction" };
  });
  const fullMenu = [...allMethods, ...gettingStartedMethods, ...intros];
  const fuse = new Fuse(fullMenu, { keys: ["summary"] });

  const generateSearchResults = () => {
    const filteredMethods = fuse.search(searchValue.toLowerCase());

    if (filteredMethods.length === 0) {
      return (
        <li
          key={"Nothing found"}
          className="block py-2 px-4 dpd-border-top bg-[#0000000d] dark:bg-[#272729] cursor-default"
        >
          Nothing found
        </li>
      );
    }

    return filteredMethods.map((method) => {
      return (
        <li
          className="block dpd-border-top hover:bg-[#0000000d] hover:dark:bg-[#272729] z-50"
          onClick={() => setRecentSearches([...recentSearches, method])}
          key={method.item.summary + method.item.docName}
        >
          <SearchItem method={method.item} />
        </li>
      );
    });
  };

  const generateRecentSearches = () => {
    const filteredSearches = recentSearches
      .reduce((acc, search) => {
        const existingIndex = acc.findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(search)
        );
        if (existingIndex === -1) {
          acc.push(search);
        }
        return acc;
      }, [])
      .slice(-5)
      .reverse();
    return filteredSearches.map((search) => (
      <li
        className="block dpd-border-top hover:bg-[#0000000d] hover:dark:bg-[#272729] z-50"
        key={search.item.summary + search.item.docName}
      >
        <SearchItem method={search.item} />
      </li>
    ));
  };

  const handleHideRecent = () => {
    if (!onRecent) {
      setShowRecent(false);
    }
  };

  return (
    <>
      <div className="relative flex items-center w-full">
        <input
          value={searchValue}
          placeholder="Search the docs"
          onChange={(e) => setSearchValue(e.target.value)}
          onFocus={() => setShowRecent(true)}
          onBlur={() => {
            searchValue.length < 1 ? handleHideRecent() : "";
          }}
          className="w-full dpd-text dpd-border-right pl-4 py-4 bg-white dark:bg-[#1B1B1D] dark:text-[#ADB5BD] focus:outline-none focus:bg-[#0000000d] focus:dark:bg-[#272729]"
          data-test="menu-search-bar"
        />
        {searchValue.length > 2 && (
          <button
            type="button"
            className="absolute right-2 top-1/2 -translate-y-1/2 px-1 py-1 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
            onClick={() => {
              setSearchValue("");
              handleHideRecent();
            }}
          >
            <ClearIcon />
          </button>
        )}
      </div>
      {searchValue.length > 2 ? (
        <ul
          className="z-100 absolute bg-white dark:bg-[#1B1B1D] dark:text-[#ADB5BD] dpd-border-bottom dpd-border-right w-full"
          data-test="search-results"
        >
          {generateSearchResults()}
        </ul>
      ) : (
        recentSearches.length > 0 &&
        showRecent && (
          <ul
            className="z-100 absolute bg-white dark:bg-[#1B1B1D] dark:text-[#ADB5BD] dpd-border-bottom dpd-border-right w-full"
            onMouseEnter={() => setOnRecent(true)}
            onMouseLeave={() => setOnRecent(false)}
            data-test="recent-searches"
          >
            {generateRecentSearches()}
          </ul>
        )
      )}
    </>
  );
};

export default MenuSearch;

MenuSearch.propTypes = {
  allMethods: PropTypes.array,
  gettingStartedVersions: PropTypes.array,
};
