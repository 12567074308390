import React from "react";
import ReactDOM from "react-dom";

const Spinner = ({ inline = false }) => {
  if (inline) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <img
          className="w-[50px] h-[50px]"
          src="/images/dpd-spinner.gif"
          alt="Loading..."
        />
      </div>
    );
  }
  return ReactDOM.createPortal(
    <React.Fragment>
      <div className="fixed top-0 left-0 z-40 w-screen h-screen bg-black opacity-50" />
      <div
        className="fixed top-0 left-0 z-50 w-full h-full overflow-x-hidden overflow-y-auto"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div className="z-[70] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <img
            className="w-[50px] h-[50px]"
            src="/images/dpd-spinner.gif"
            alt="Loading..."
          />
        </div>
      </div>
    </React.Fragment>,
    document.body
  );
};

export default Spinner;
