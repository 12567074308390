import PropTypes from "prop-types";

const Anchor = ({ value, href }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="text-[#DC0032] underline"
    >
      {value}
    </a>
  );
};

export default Anchor;

Anchor.propTypes = {
  value: PropTypes.array,
  href: PropTypes.string,
};
