import React from "react";
import { Link, useLocation } from "react-router-dom";

import { ThemeContext } from "../../context/ThemeContext";
import { capitalizeFirstLetter } from "../../helpers/strings";

import { ApiStatusPill } from "./";
import { DarkModeToggleIcon, DPDLogo, Hamburger } from "../Svgs";

const formatName = (name) =>
  name ? name.split("-").map(capitalizeFirstLetter).join(" ") : "undefined";

const TopNav = () => {
  const { pathname } = useLocation();
  const { toggleTheme } = React.useContext(ThemeContext);

  let pageName;
  let versionName;

  if (pathname === "/") {
    pageName = "Getting Started";
  } else {
    pageName = formatName(decodeURIComponent(pathname.split("/")[1]));
    versionName = formatName(decodeURIComponent(pathname.split("/")[2]));
  }

  return (
    <header className="bg-white relative z-40">
      <section className="flex justify-between p-[8px] border-b-2">
        <div className="flex items-center">
          <Hamburger />

          <Link to="/">
            <DPDLogo />
          </Link>
          <p className="dpd-nav-text mt-[5px] md:visible invisible">
            {capitalizeFirstLetter(pageName)}{" "}
            {versionName &&
              versionName !== "undefined" &&
              `- ${capitalizeFirstLetter(versionName)}`}
          </p>
        </div>
        <div className="flex items-center">
          <ApiStatusPill />
          <button onClick={toggleTheme}>
            <DarkModeToggleIcon />
          </button>
        </div>
      </section>
    </header>
  );
};

export default TopNav;
