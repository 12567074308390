const ChevronIcon = () => (
  <svg
    width="3"
    height="6"
    viewBox="0 0 3 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.57085 0L0 0.705L1.85425 3L0 5.295L0.57085 6L3 3L0.57085 0Z"
      fill="#808285"
    />
  </svg>
);

export default ChevronIcon;
