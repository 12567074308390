import PropTypes from "prop-types";

import { capitalizeFirstLetter, sanitisePath } from "../../helpers/strings";

import { ScrollButton } from "../Actionable";
import MessageCard from "../Typography/MessageCard";

export const SubPageBody = ({ sections }) => {
  return (
    <div className="mt-[12px]">
      <div className="flex-row">
        {sections.map((section, index) => (
          <section
            className="py-[24px]"
            key={section.summary}
            id={sanitisePath(section.summary)}
          >
            <h1 className="text-xl font-[370] tracking-heading">
              {capitalizeFirstLetter(section.summary)}
            </h1>
            <div className="dpd-border-bottom 1p pt-[8px] pb-[16px] solid" />
            {section.body}
            {section.messageCards &&
              section.messageCards.map((card, index) => (
                <MessageCard
                  key={card.type + card.message}
                  messageType={card.type}
                  message={card.message}
                />
              ))}
          </section>
        ))}
      </div>
      <ScrollButton />
    </div>
  );
};

SubPageBody.propTypes = {
  sections: PropTypes.array,
};
