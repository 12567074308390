import { useMemo } from "react";

export default function useDropdownMenuItems(sections) {
  return useMemo(() => {
    if (!Array.isArray(sections)) {
      return [];
    }

    return sections.map((section) => ({
      label: section.summary,
      props: {},
    }));
  }, [sections]);
}
