import PropTypes from "prop-types";
import React, { useEffect, useState, lazy, Suspense } from "react";
const CodeViewer = lazy(() => import("../Typography/CodeViewer"));
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { DATA_TEST } from "../../constants/testing/dataTest";
import { ThemeContext } from "../../context/ThemeContext";

import { Select } from "../Actionable/";
import { Button } from "../Actionable/";
import Spinner from "../Spinner";
import JsonBlock from "../Typography/JsonBlock";
import { MarkdownComponents } from "../Typography/Markdown";
import MessageCard from "../Typography/MessageCard";

const getExamplePicker = ({
  multipleExamples,
  exampleList,
  setSelectedRequestExample,
  setEditBody,
  tryItOut,
  selectedRequestExample,
}) => {
  if (multipleExamples && exampleList) {
    return (
      <div className="flex justify-between items-end">
        <Select
          labelTitle="Examples"
          onChange={(e) => {
            setSelectedRequestExample(e.target.value);
            setEditBody(
              JSON.stringify(multipleExamples[e.target.value].value, null, 2)
            );
          }}
          selectList={exampleList}
          dataTest={DATA_TEST.REQUEST_EXAMPLES}
        />
        {tryItOut ? (
          <Button
            styleVariant="redOutline"
            className="ml-auto rounded-md mb-0"
            onClick={() => {
              setEditBody(
                JSON.stringify(
                  multipleExamples[selectedRequestExample].value,
                  null,
                  2
                )
              );
            }}
          >
            Reset
          </Button>
        ) : null}
      </div>
    );
  }

  return null;
};
const getButtonBar = () => (
  <div className="flex">
    <button className="dpd-text px-[8px] py-[16px] border-b-[2px] border-[#DC0032] mb-[-2px]">
      JSON
    </button>
  </div>
);
const getCodeViewer = ({ tryItOut, body, setEditBody, mode }) => {
  //viewing examples
  if (!tryItOut) {
    return <JsonBlock value={body} />;
  }

  //editing
  return (
    <>
      <Suspense fallback={<Spinner inline={true} />}>
        <CodeViewer
          value={body}
          onChange={(e) => {
            setEditBody(e);
          }}
          inputMode={true}
          minHeight="300px"
        />
      </Suspense>
    </>
  );
};

const getDescription = ({ multipleExamples, selectedRequestExample }) => {
  if (multipleExamples?.[selectedRequestExample]?.description) {
    return (
      <MessageCard
        messageType="note"
        message={
          <ReactMarkdown
            components={MarkdownComponents}
            children={multipleExamples[selectedRequestExample].description}
            remarkPlugins={[remarkGfm]}
          />
        }
      />
    );
  }

  return null;
};

const RequestBody = ({
  body,
  tryItOut,
  setEditBody,
  multipleExamples,
  selectedRequestExample,
  setSelectedRequestExample,
}) => {
  const { theme: mode } = React.useContext(ThemeContext);
  const [exampleList, setExampleList] = useState([]);

  useEffect(() => {
    if (multipleExamples) {
      const keys = Object.keys(multipleExamples);
      setExampleList(keys);
      setSelectedRequestExample(keys[0]);
    }
  }, [multipleExamples, setSelectedRequestExample]);

  useEffect(() => {
    if (selectedRequestExample && Object.keys(multipleExamples)?.length) {
      setEditBody(
        JSON.stringify(multipleExamples[selectedRequestExample].value, null, 2)
      );
    }
  }, [selectedRequestExample, multipleExamples, setEditBody]);

  return (
    <div className="my-[24px]">
      <div className="flex justify-between">
        <h1 className="dpd-heading">Request Body</h1>
      </div>

      {getExamplePicker({
        multipleExamples,
        exampleList,
        setSelectedRequestExample,
        setEditBody,
        tryItOut,
        selectedRequestExample,
      })}
      {getDescription({ multipleExamples, selectedRequestExample })}
      {getButtonBar()}
      <div className="mb-2" />
      {getCodeViewer({ tryItOut, body, setEditBody, mode })}
    </div>
  );
};

export default RequestBody;

RequestBody.propTypes = {
  body: PropTypes.string,
  tryItOut: PropTypes.bool,
  setEditBody: PropTypes.func,
  multipleExamples: PropTypes.object,
  schema: PropTypes.object,
  showSchema: PropTypes.bool,
  setShowSchema: PropTypes.func,
  selectedRequestExample: PropTypes.string,
  setSelectedRequestExample: PropTypes.func,
  setRequestBodyApplicationType: PropTypes.func,
  requestApplicationTypes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};
