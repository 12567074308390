import PropTypes from "prop-types";
import React, { memo } from "react";

import ResponseTableItem from "./ResponseTableItem";
import { CollapsibleHeader } from "../Actionable/";

const ExampleResponseTable = ({ responses }) => {
  const generateRows = (responses) => {
    return responses.map((res, index) => {
      return <ResponseTableItem response={res} key={index} />;
    });
  };

  return (
    <CollapsibleHeader title="Responses">
      <table className="mt-[16px] dpd-border-table w-full">
        <thead>
          <tr>
            <th className="dpd-text dpd-border-table w-[10%] text-left py-[8px] px-[16px]">
              Code
            </th>
            <th className="dpd-text dpd-border-table text-left py-[8px] px-[16px]">
              Description
            </th>
          </tr>
        </thead>
        <tbody>{generateRows(responses)}</tbody>
      </table>
    </CollapsibleHeader>
  );
};

export default memo(ExampleResponseTable);

ExampleResponseTable.propTypes = {
  responses: PropTypes.array,
};
