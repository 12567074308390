const HomeIcon = ({ key }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-2"
      key={key}
    >
      <g clipPath="url(#clip0_24_7157)">
        <path
          d="M6.66683 13.3333V9.33333H9.3335V13.3333H12.6668V8H14.6668L8.00016 2L1.3335 8H3.3335V13.3333H6.66683Z"
          fill="#808285"
        />
      </g>
      <defs>
        <clipPath id="clip0_24_7157">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeIcon;
