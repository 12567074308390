import { HTTP_METHODS } from "../../constants";

const ApiMethodIndicator = ({ method }) => {
  if (method === HTTP_METHODS.GET) {
    return (
      <span className="inline-block w-2 h-2 mr-2 bg-blue-500 rounded-full" />
    );
  }
  if (method === HTTP_METHODS.POST) {
    return (
      <span className="inline-block w-2 h-2 mr-2 bg-green-500 rounded-full" />
    );
  }

  return null;
};

export default ApiMethodIndicator;
