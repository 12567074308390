const RoundedBorder = ({ children, backgroundColor = "white" }) => {
  return (
    <div
      className="container mx-auto mt-4 p-4 rounded-[10px]"
      style={{ backgroundColor }}
    >
      {children}
    </div>
  );
};

export default RoundedBorder;
