const { SECURITY } = require("../constants");

export const getAuthHeader = (
  authMethod,
  basicAuth,
  accessToken,
  refreshToken
) => {
  if (SECURITY.BASIC_AUTH === authMethod && basicAuth) {
    return basicAuth;
  } else if (SECURITY.BEARER_REFRESH === authMethod && refreshToken) {
    return refreshToken;
  } else if (SECURITY.BEARER_TOKEN === authMethod && accessToken) {
    return accessToken;
  }
  return null;
};
export const getAuthHeaderStringAuthMethod = (
  authMethod,
  basicAuth,
  accessToken,
  refreshToken
) => {
  if (SECURITY.BASIC_AUTH === authMethod && basicAuth) {
    return `Basic ${basicAuth}`;
  } else if (SECURITY.BEARER_REFRESH === authMethod && refreshToken) {
    return `Bearer ${refreshToken}`;
  } else if (SECURITY.BEARER_TOKEN === authMethod && accessToken) {
    return `Bearer ${accessToken}`;
  }
  return null;
};

export const getDescriptionForAuthMethod = (authMethod) => {
  let baseStr = "";
  if (SECURITY.BASIC_AUTH === authMethod) {
    baseStr += "Your Key and Secret base64 encoded.";
  } else if (SECURITY.BEARER_REFRESH === authMethod) {
    baseStr += "Your JWT Refresh Token.";
  } else if (SECURITY.BEARER_TOKEN === authMethod) {
    baseStr += "Your JWT Access Token.";
  }
  baseStr += " See our authorize button above to set this variable.";
  return baseStr;
};

export const getExampleForAuthMethod = (authMethod) => {
  if (SECURITY.BASIC_AUTH === authMethod) {
    return "Basic base64encoded(key:secret)";
  } else if (SECURITY.BEARER_REFRESH === authMethod) {
    return "Bearer jwt.refreshToken.here";
  }
  return "Bearer jwt.accessToken.here";
};
