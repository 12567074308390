import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import { FILE_TYPES, REGEX } from "../../constants";
import { capitalizeFirstLetter, getPageTitle } from "../../helpers/strings";

import {
  TryItOutButton,
  AuthoriseButton,
  DownloadDocsDropdown,
} from "../Actionable";
import { ChevronIcon, HomeIcon } from "../Svgs";

const getHomeBreadCrumb = () => {
  const navigate = useNavigate();

  return (
    <button key={"home"} onClick={() => navigate("/")}>
      <HomeIcon />
    </button>
  );
};

const getBreadCrumbLink = (link, idx = 0, links) => {
  const grenateHref = (index) => {
    if (index < 0 || index >= links.length) {
      return links;
    }
    return links.slice(0, index + 1).join("/");
  };

  return (
    <React.Fragment key={link}>
      <span className="mr-2">
        <ChevronIcon />
      </span>
      <a
        className="mr-2 dpd-text-small hover:dpd-red pointer"
        href={grenateHref(idx)}
        key={link}
      >
        {decodeURIComponent(capitalizeFirstLetter(link))}
      </a>
    </React.Fragment>
  );
};

const Breadcrumb = ({
  paths,
  isAuthed,
  showModal,
  swaggerPath,
  combineVersions = false, // use this when you only want the root + rest of path to be a single breadcrumb
}) => {
  const links = paths.split("/");

  const downloadFile = (fileType) => {
    const url =
      process.env.PUBLIC_URL +
      `/docs/${swaggerPath.substring(0, swaggerPath.lastIndexOf("."))}.${fileType}`;
    const link = document.createElement("a");
    link.href = url;
    link.download = `${getPageTitle(paths)}-${links[links.length - 1]}-schema.${fileType}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadYAML = () => {
    downloadFile(FILE_TYPES.YAML);
  };

  const handleDownloadJSON = () => {
    downloadFile(FILE_TYPES.JSON);
  };

  const getSingleBreadcrumbFromLinks = (linksArr) => {
    const link = linksArr.reduce((returnStr, link) => {
      returnStr += decodeURIComponent(capitalizeFirstLetter(link));

      if (REGEX.VERSION.test(link)) {
        returnStr += " / ";
      }

      return returnStr;
    }, "");

    return getBreadCrumbLink(link, 0, links);
  };

  const renderBreadcrumbs = () => {
    const linksArr = [];
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      if (i === 0) {
        linksArr.push(getHomeBreadCrumb());
        continue;
      }

      if (combineVersions && REGEX.VERSION.test(link)) {
        //we found a version e.g v1 set the rest of the path to that single url
        linksArr.push(getSingleBreadcrumbFromLinks(links.slice(i)));
        return linksArr;
      } else {
        //set a short breadcrumb link for the selected index
        linksArr.push(getBreadCrumbLink(link, i, links));
      }
    }
    return linksArr;
  };

  return (
    <div className="flex flex-col sm:flex-row mt-[16px] sm:my-[16px] items-center">
      <div className="flex flex-row items-center">
        {paths && renderBreadcrumbs()}
      </div>
      {swaggerPath && (
        <div className="flex flex-col sm:flex-row mt-4 sm:mt-0 sm:ml-auto">
          <span className="pb-[8px] dpd-text-button flex flex-row">
            <AuthoriseButton isAuth={isAuthed} onClick={() => showModal()} />
            <div className="ml-[5px] relative">
              <DownloadDocsDropdown
                menuItems={[
                  { label: "JSON", props: { onClick: handleDownloadJSON } },
                  { label: "YAML", props: { onClick: handleDownloadYAML } },
                ]}
              />
            </div>
            <TryItOutButton className="ml-[5px]" />
          </span>
        </div>
      )}
    </div>
  );
};

export default Breadcrumb;

Breadcrumb.propTypes = {
  paths: PropTypes.string,
  isAuthed: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showModal: PropTypes.func,
  swaggerPath: PropTypes.string,
};
