import { Link } from "react-router-dom";

import { Button } from "../Actionable/";
import ThinkingFace from "../Svgs/ThinkingFace";

export default function ErrorTryAgain() {
  return (
    <section className="flex flex-col pt-[56px] px-[50px] pb-[16]">
      <ThinkingFace />
      <h1 className="text-center font-pluto dark:text-dark-text text-[26px] font-[400] tracking-heading text-[#414042] mb-[16px]">
        Something has gone wrong
      </h1>
      <p className="text-center font-pluto text-[14px] dark:text-dark-text font-[400] leading-5 tracking-heading text-[#414042] mb-[32px]">
        please try again
      </p>
      <Link className="self-center" reloadDocument to={""}>
        <Button
          styleVariant={"redSolid"}
          className={"rounded-md"}
          data-test="error-try-again"
        >
          <p className="text-center font-pluto text-base font-[400] tracking-heading">
            Try Again
          </p>
        </Button>
      </Link>
    </section>
  );
}
