import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import OnRouteChangeScrollHelper from "./components/Actionable/OnRouteChangeScrollHelper";
import docsSteps from "./constants/docs";
import gettingStartedSteps from "./constants/gettingStarted/gettingStartedSteps";
import { SideNavProvider } from "./context/StateNavContext";
import { ThemeProvider } from "./context/ThemeContext";
import { TryItOutProvider } from "./context/TryItOutContext";
import allMethods from "./docs/allMethods.json";
import { joinWithHyphen } from "./helpers/strings";
import DocsLayout from "./layout/DocsLayout";
import Layout from "./layout/Layout";
import PageNotFound from "./pages/404";
import GettingStarted from "./pages/GettingStarted";
import SubPage from "./pages/SubPage";

const { apis } = require("./docs/index.json");

const getSections = (name) => docsSteps?.[name]?.sections || [];
const getGettingStartedSubPages = (allMethods) =>
  gettingStartedSteps.map((step) => (
    <Route
      key={step.path}
      path={`/getting-started/${step.path}`}
      element={
        <SubPage
          apis={apis}
          allMethods={allMethods}
          pageName={step.title}
          sections={step.sections}
        />
      }
    />
  ));

const getGettingStartedRoutes = (allMethods) => (
  <>
    <Route
      path="/"
      element={<GettingStarted apis={apis} allMethods={allMethods} />}
    />
    <Route path="/getting-started" element={<Navigate to="/" replace />} />
    {getGettingStartedSubPages(apis, allMethods)}
  </>
);

const getSwaggerDocRoutes = (allMethods) => {
  return apis.map((route) => {
    const name = route.name.toLowerCase();
    const versions = route.versions.map((version) => {
      return (
        <React.Fragment key={version}>
          {allMethods.map((method) => (
            <Route
              key={method}
              path={`/${name}/${version.name.toLowerCase()}/${joinWithHyphen(
                method.summary
              )}`}
              element={
                <DocsLayout
                  apis={apis}
                  allMethods={allMethods}
                  method={method}
                  swaggerPath={`${route.path}/${version.path}/schema.json`}
                />
              }
            />
          ))}
        </React.Fragment>
      );
    });

    const home = [
      <Route
        path={`/${name}`}
        element={<Navigate to={`/${name}/introduction`} replace />}
      />,
      <Route
        path={`/${name}/introduction`}
        element={
          <SubPage
            apis={apis}
            allMethods={allMethods}
            pageName={name}
            sections={getSections(name)}
          />
        }
      />,
      <Route
        path={`/${name}/latest`}
        element={<Navigate to={`/${name}/introduction`} replace />}
      />,
    ];
    return [...home, ...versions];
  });
};

export default function PageRouter() {
  return (
    <Router>
      <ThemeProvider>
        <TryItOutProvider>
          <SideNavProvider>
            <OnRouteChangeScrollHelper />
            <Layout apis={apis} allMethods={allMethods}>
              <Routes>
                {getGettingStartedRoutes(allMethods)}
                {getSwaggerDocRoutes(allMethods)}
                <Route
                  path="*"
                  element={<PageNotFound allMethods={allMethods} />}
                />
              </Routes>
            </Layout>
          </SideNavProvider>
        </TryItOutProvider>
      </ThemeProvider>
    </Router>
  );
}
