import React from "react";

import { Link } from "../../components/Actionable/";
import BodyParagraph from "../../components/Typography/BodyParagraph";
import MessageCard from "../../components/Typography/MessageCard";

import { PATHS } from "..";

const sections = [
  {
    summary: "Overview",
    body: (
      <>
        <BodyParagraph>
          In order to be able to utilize DPD UK Web services there is a
          prerequisite to hold a DPD UK or DPD Local UK Account along with
          username and password.
        </BodyParagraph>
        <BodyParagraph>
          These are the details that you will have been provided by your account
          manager to access the DPD UK/ DPD Local website / MyDPD App. Without
          an account/login it will not be possible to access these services.
        </BodyParagraph>
        <BodyParagraph>
          If you hold an account but believe you have not been issued with login
          details or If you require a dedicated login for your API
          implementation please contact your DPD UK account manager and they
          will be able to assist you.
        </BodyParagraph>
        <BodyParagraph>
          For 3rd Party developers working on behalf of a mutual customer, where
          no username and password have yet been provided, please contact DPD to
          request a test username & password / API Key & Secret that can be
          utilised whilst in the testing phase.
        </BodyParagraph>
        <BodyParagraph>
          Your username and password will be required to access Customer portal
          to enable you to manage login credentials and subsequently generate an
          API Key and Secret to be able to access the API's.
        </BodyParagraph>
        <BodyParagraph>Please see link below for instructions:</BodyParagraph>
        <BodyParagraph>
          <Link to={PATHS.CUSTOMER_PORTAL}>
            See our customer portal instructions below
          </Link>{" "}
        </BodyParagraph>
      </>
    ),
    messageCards: [
      {
        type: "note",
        message:
          "Any test logins/tokens provided must not be shared and only be used within the test process and not used for live shipping once sign off has been obtained.",
      },
    ],
  },
  {
    summary: "Customer Portal",
    body: (
      <>
        <MessageCard
          messageType="note"
          message="If you dont have access to customer portal please speak to your account manager"
        />
        <BodyParagraph>
          Navigate to{" "}
          <Link to={PATHS.EXTERNAL.MY_ADMIN}>Customer Admin Portal</Link> and
          Login using the credentials provided. There is a requirement to be an
          admin user of an account, This can be usually identified if you have
          an email address as a logon.
        </BodyParagraph>
        <BodyParagraph>
          Once you have logged in, Under the Manage Users Tab on the left,
          Select the specific user you wish to add the API Keys to (A new user
          can also be created here to subsequently add keys to).
        </BodyParagraph>
        <BodyParagraph>
          On the user management screen, scroll down to the “API Setting”
          section where you may find the API Access is set as disabled for a
          first-time user.
        </BodyParagraph>
        <BodyParagraph>
          In order for this user to be able to utilize API’s this needs to be
          enabled. When enabling API access, you will be requested to enter an
          email address if the user doesn't already have a confirmed email
          address.
        </BodyParagraph>
        <BodyParagraph>
          Once the email address has been entered and the “Verify" button is
          clicked, an email will be sent to the provided email address, The user
          will need to verify their email address validity before being able to
          create API Keys by opening the email received.
          <br />
          It is then required to click the “Verify Email" button located within
          the email in order to verify, the user will be taken to a screen
          showing the following pop-up.
        </BodyParagraph>
        <BodyParagraph>
          Once verification has been completed API Keys can be added to the user
          for both sandbox and live environments.
        </BodyParagraph>
        <BodyParagraph>
          When clicking the “Add New Key” button, the applicable API key and
          secret are shown.
        </BodyParagraph>
        <BodyParagraph>
          It is suggested that the secret is copied to ensure accuracy.
        </BodyParagraph>
        <MessageCard
          messageType="warning"
          message="The secret must be copied and stored at this point when displayed on screen, the secret is only shown once and is not possible to view again after this point."
        />
        <BodyParagraph>
          Once the keys/ secrets are generated and the "Close” button is
          clicked, a screen will be shown where only the key can be seen.
        </BodyParagraph>
        <MessageCard
          messageType="note"
          message="Up to 2 keys per environment can be added. Once two keys have been added, it is possible to replace keys by deleting one of the existing keys. This is made possible to allow customers the ability to create new keys,  whilst also retaining a valid key within your system to allow seamless switching between new and old keys."
        />
      </>
    ),
  },
];

export default sections;
