import React, { useContext } from "react";

import NavCard from "../components/Navigation/NavCard";
import SwaggerSchema from "../components/Swagger/SwaggerSchema";
import SwaggerSection from "../components/Swagger/SwaggerSection";
import { TryItOutContext } from "../context/TryItOutContext";

const SelectedDoc = ({
  swaggerData,
  isSmallScreen,
  authToken,
  authTokenRefresh,
  toggle,
  previousStep,
  nextStep,
  baseUrl,
  basicAuth,
}) => {
  const { showTryItOut } = useContext(TryItOutContext);

  if (!swaggerData) {
    return null;
  }
  const renderSwaggerSection = (
    {
      summary,
      description,
      params,
      requestBodyExample,
      schema,
      responses,
      method,
      urlPath,
      authMethod,
      requestContentTypes,
      responseAcceptTypes,
    },
    index
  ) => (
    <div
      className="h-full sm:h-[80vh] sm:w-auto w-full overflow-hidden sm:overflow-visible"
      key={summary}
    >
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 w-full">
        <div
          className={`w-full ${
            showTryItOut ? "sm:w-1/2" : "sm:w-full"
          } overflow-auto h-full sm:h-[85vh] transition-width transition-padding transition-margin duration-500 ease-in-out`}
        >
          <SwaggerSchema
            schema={schema}
            responses={responses}
            summary={summary}
            method={method}
            urlPath={urlPath}
            showTryItOut={showTryItOut}
            description={description}
          />
        </div>
        {(showTryItOut || isSmallScreen) && (
          <div className="w-full sm:w-1/2 overflow-auto sticky sm:top-0 sm:right-0 z-20 h-full sm:h-[85vh] transition-all duration-500 ease-in-out transform translate-x-0">
            <SwaggerSection
              summary={summary}
              description={description}
              params={params}
              requestBodyExample={requestBodyExample}
              method={method}
              authToken={authToken}
              authTokenRefresh={authTokenRefresh}
              basicAuth={basicAuth}
              toggleModal={toggle}
              urlPath={urlPath}
              authMethod={authMethod}
              requestContentTypes={requestContentTypes}
              responseAcceptTypes={responseAcceptTypes}
            />
          </div>
        )}
      </div>
      <div className="flex my-4">
        {previousStep && (
          <NavCard
            navType="Previous"
            navLocation={`${previousStep}`}
            baseUrl={baseUrl}
          />
        )}
        {nextStep && (
          <NavCard
            navType="Next"
            navLocation={`${nextStep}`}
            baseUrl={baseUrl}
          />
        )}
      </div>
    </div>
  );

  return <>{renderSwaggerSection(swaggerData)}</>;
};

export default SelectedDoc;
