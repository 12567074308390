import React from "react";

import { PAGES, PATHS } from "../../index";

import { Link } from "../../../components/Actionable/";
import SVGViewer from "../../../components/Actionable/SVGViewer";
import { JsonBlock } from "../../../components/Typography";
import BodyParagraph from "../../../components/Typography/BodyParagraph";
import MessageCard from "../../../components/Typography/MessageCard";
import { withCachebuster } from "../../../helpers/cache";
import { sanitisePath } from "../../../helpers/strings";
import { ERROR_HANDLING as AUTH_ERROR_HANDLING } from "../auth/sections";
import getAvailableEndpoints from "../helpers/page";

export const INTRODUCTION = {
  summary: "Introduction",
  body: (
    <>
      <BodyParagraph>
        As a DPD / DPD Local customer you may have a requirement to raise a
        collection, this may be to collect an item from a consumer that has been
        pre-labelled for return and needs to be delivered back to yourselves for
        example.
      </BodyParagraph>
      <BodyParagraph>
        Our collections web services allow you to check available collection
        days against postcode/address within the UK, request and manage any
        subsequent collections.
      </BodyParagraph>
      <BodyParagraph>
        A DPD driver will attend the defined collection address and collect any
        applicable parcel/s, return through the DPD parcel network and
        subsequently deliver to the defined address.
      </BodyParagraph>
      <BodyParagraph>
        Depending on the action you would like to take the API endpoint you will
        need to send requests to will differ, Please see a brief description for
        each API endpoint as an aid on each API’s functionality:
      </BodyParagraph>
      <BodyParagraph.Header>Create Collection</BodyParagraph.Header>
      <BodyParagraph>
        This API allows creation of new collection request/s, in turn requests a
        DPD Driver to attend a particular collection location to collect
        parcel/s
      </BodyParagraph>
      <BodyParagraph.Header>Cancel Collection</BodyParagraph.Header>
      <BodyParagraph>
        This API allows for a previously requested / booked collection to be
        cancelled if this is no longer required to be carried out.
      </BodyParagraph>
      <BodyParagraph.Header>Get Collection Dates</BodyParagraph.Header>
      <BodyParagraph>
        This API exists to validate the available collection dates that a driver
        would be able to attend a particular destination to collect parcel/s.
      </BodyParagraph>
      <MessageCard
        messageType="note"
        message="Only collection requests to collect from GB destinations are currently supported."
      />
      <BodyParagraph>
        Explore the technical documentation for our available endpoints below:
      </BodyParagraph>
      <BodyParagraph>{getAvailableEndpoints(PAGES.COLLECTIONS)}</BodyParagraph>
    </>
  ),
};

export const FLOW_INTEGRATIONS = {
  summary: "Integration",
  body: (
    <>
      <MessageCard
        messageType={"note"}
        message={
          <div style={{ wordWrap: "break-word" }}>
            In order to use Collections endpoints there is a prerequisite to
            have integrated with DPD UK authentication API’s in order to
            authenticate with Collections API’s. Please refer to our{" "}
            <Link to={`/${PAGES.AUTH}${PATHS.INTRODUCTION}`}>
              auth specification
            </Link>{" "}
            for more information.
          </div>
        }
      />
      <BodyParagraph>
        Example flow to access to create a collection:
      </BodyParagraph>
      <SVGViewer
        src={withCachebuster("/images/svg/CreateCollectionFlow.svg")}
      />

      <BodyParagraph>Example flow to cancel a collection:</BodyParagraph>
      <SVGViewer
        src={withCachebuster("/images/svg/CancelCollectionFlow.svg")}
      />
    </>
  ),
};

export const PRELABELLED = {
  summary: "Prelabelled",
  body: (
    <>
      <BodyParagraph>
        It is possible to request a DPD driver to attend a defined collection
        address and collect a parcel where parcels are pre labelled
      </BodyParagraph>
      <BodyParagraph>
        Pre labelled = Where a shipping label has already been generated via
        shipping solution and has been affixed to the parcel prior to the
        collection driver arrival.
      </BodyParagraph>
    </>
  ),
};

export const SANDBOX_VS_LIVE = {
  summary: "Sandbox Environment vs Live Environment",
  body: (
    <>
      <BodyParagraph>
        The test URLs and endpoints are a copy of the live production API
        however collections functionality via sandbox differs and is limited
        compared to the live environment. Please see description of the
        collections sandbox API’s behaviour below for your reference.
      </BodyParagraph>
      <BodyParagraph>
        As the sandbox environment is independent of the live environment it is
        required to manage API keys independently, in order to raise collections
        via live API’s it is required to create API key for the live environment
        as sandbox and live keys are not interchangeable.
      </BodyParagraph>
      <BodyParagraph.Header>Create collection</BodyParagraph.Header>
      <BodyParagraph>
        The collection sandbox will only allow the provided example collection
        JSON payload within the portal to be sent and accepted to demonstrate a
        successful request response.
      </BodyParagraph>
      <MessageCard
        messageType="note"
        message="Please note any amendment to this example payload when sent will return an error similar to below, please note this error will not be returned within the live environment."
      />
      <JsonBlock
        value={JSON.stringify(
          {
            error: [
              {
                code: 101,
                links: {
                  self: "/v1/customer/collection",
                },
                status: 400,
                fieldName: "",
                fieldPath: "",
                displayType: "",
                rule: "",
                timestamp: 1730718676008,
                type: "Application",
                message:
                  "This is a sample error, please use the parameter examples provided on the portal to get a valid response",
                id: "3d6b419454dd406e9688a025807039d8",
              },
            ],
          },
          null,
          2
        )}
      />
      <BodyParagraph>
        The successful response will return a fixed collectionCode that can be
        used in conjunction with the Cancel collection API.
      </BodyParagraph>
      <BodyParagraph.Header>Cancel collection</BodyParagraph.Header>
      <BodyParagraph>
        The Cancel collection API allows you to send a request to cancel the
        provided collectionCode and returns a successful example request
        response. (The collectionCode value used is returned as part of the
        Create collection API response).
      </BodyParagraph>
      <MessageCard
        messageType="note"
        message="Any collectionCode value passed in other than the example provided will present an error similar to the error message given above."
      />
      <BodyParagraph.Header>Get collection dates</BodyParagraph.Header>
      <BodyParagraph>
        The collection sandbox will only allow the provided example Get
        collection dates API request presented within the portal to be sent and
        accepted to return a successful request response.
      </BodyParagraph>
      <MessageCard
        messageType="note"
        message="Any postcode value passed other than the example value given, this will present an error similar to the error message provided above."
      />
    </>
  ),
};

export const SANDBOX_TEST = {
  summary: "Sandbox Test Collections",
  body: (
    <>
      <BodyParagraph>
        The sandbox environment API’s can assist with your Collections API
        integration by allowing you to send an example request to Get collection
        dates API, send example request to create collection API and send
        example request to cancel collection API via the relevant sandbox
        endpoints.
      </BodyParagraph>
      <MessageCard
        messageType={"note"}
        message={
          <div style={{ wordWrap: "break-word" }}>
            Please note any collection requests or cancellations made via the
            sandbox environment will not be fulfilled or fulfilled in the
            production environment.
          </div>
        }
      />
    </>
  ),
};

export const RATE_LIMITING = {
  summary: "Rate Limiting",
  body: (
    <>
      <BodyParagraph>
        The DPD UK API sandbox features stricter rate limits than the production
        environment, this is put in place to enable fair use for all customers.
      </BodyParagraph>
    </>
  ),
};

export const ERROR_HANDLING = {
  summary: "Error Handling",
  body: (
    <>
      <MessageCard
        messageType="note"
        message="Collection endpoints can return both authentication based errors and also collection related errors, the response schema of Authentication related errors and Collection related errors differ so you will need to be able to cater for both types of error responses."
      />
      <BodyParagraph>
        To see details of authentication based errors please visit our{" "}
        <Link
          to={`/${PAGES.AUTH}${PATHS.INTRODUCTION}/#${sanitisePath(AUTH_ERROR_HANDLING.summary)}`}
        >
          Authentication specification
        </Link>{" "}
        for more information.
      </BodyParagraph>
      <BodyParagraph.Header>
        Examples of common Collection Errors
      </BodyParagraph.Header>
      <BodyParagraph>
        Please see types of examples of error codes that can be received, these
        are categorized by code/s types.
      </BodyParagraph>
      <BodyParagraph>
        <div className="markdown-table overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr>
                <th>type</th>
                <th>code</th>
                <th>status</th>
                <th>description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>System</td>
                <td>100</td>
                <td>400</td>
                <td>System errors (e.g database error)</td>
              </tr>
              <tr>
                <td>Application</td>
                <td>101</td>
                <td>400</td>
                <td>User rule errors (e.g collections disabled)</td>
              </tr>
              <tr>
                <td>Business</td>
                <td>102</td>
                <td>400</td>
                <td>
                  Business rule errors (e.g network is unavailable for
                  destination)
                </td>
              </tr>
              <tr>
                <td>FieldValidation</td>
                <td>103</td>
                <td>400</td>
                <td>Field validation (e.g max length exceeded)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BodyParagraph>
      <BodyParagraph>
        Detailed error information is provided within the message field included
        within the response to describe the issue with the request.
      </BodyParagraph>
      <BodyParagraph>
        For example : "message": "\"telephone\" with value \"abc\" fails to
        match the required pattern: /^\\+?\\d{(7, 15)}$/",
      </BodyParagraph>
      <BodyParagraph>
        The fieldName and fieldpath also provides the affected fieldName where
        the issue has been detected and also the fieldPath gives the object /
        array on where the fieldName is located within the JSON request.
      </BodyParagraph>
      <BodyParagraph>"fieldName": "telephone"</BodyParagraph>
      <BodyParagraph>
        "fieldPath": "collection.contactDetails.telephone"
      </BodyParagraph>
      <JsonBlock
        value={JSON.stringify(
          {
            error: [
              {
                id: "e1a18ce6c810a0fa2aa88e804d8c3968",
                links: {
                  self: "/v1/customer/collection",
                },
                status: 400,
                timestamp: 1707324903729,
                code: 103,
                type: "FieldValidation",
                message:
                  '"telephone" with value "abc" fails to match the required pattern: /^\\+?\\d{7,15}$/',
                fieldName: "telephone",
                fieldPath: "collection.contactDetails.telephone",
              },
            ],
          },
          null,
          2
        )}
      />

      <BodyParagraph>
        <ul>
          <li>
            <b>System Errors (code 100):</b>
          </li>
          <ul className={"list-disc"}>
            <li>
              Using the wrong ID when performing actions on a resource (i.e
              cancel collection for an invalid collectionCode)
            </li>
          </ul>
          <br />
          <li>
            <b>Application Errors (code 101):</b>
          </li>
          <ul className={"list-disc"}>
            <li>
              User role specific errors (e.g disabling creation of collections
              via the customer portal)
            </li>
          </ul>
          <br />
          <li>
            <b>Business Errors (code 102):</b>
          </li>
          <ul className={"list-disc"}>
            <li>
              This account is on-stop - please contact your account manager if
              this occurs
            </li>
            <li>Missing information which describe your collections </li>
            <li>Invalid service/destination selected</li>
            <li>Invalid weight/number of packages</li>
          </ul>
          <br />
          <li>
            <b>Field Validation Errors (code 103):</b>
          </li>
          <ul className={"list-disc"}>
            <li>Missing mandatory fields</li>
            <li>
              Invalid dates (e.g <code>collectionDate</code>)
            </li>
            <li>Min/Max length exceeded</li>
            <li>Min/Max value exceeded</li>
            <li>Invalid format or type</li>
          </ul>
        </ul>
        <br />
      </BodyParagraph>
    </>
  ),
};
