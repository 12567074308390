import PropTypes from "prop-types";

import { getAuthHeaderStringAuthMethod } from "./security";

export const buildRequest = (
  method,
  url,
  authToken,
  body,
  paramHeaders,
  basicAuth,
  authTokenRefresh,
  authMethod,
  requestContentType,
  responseAcceptType
) => {
  let data;
  try {
    data = body ? JSON.parse(body) : "";
  } catch (error) {
    console.error("Error parsing JSON body:", error);
    return {
      error: "Invalid JSON body",
    };
  }

  const formattedHeaders = paramHeaders
    ? paramHeaders.map(({ name, value }) => ({ [name]: value }))
    : [];

  const headers = Object.assign(
    {
      Authorization: getAuthHeaderStringAuthMethod(
        authMethod,
        basicAuth,
        authToken,
        authTokenRefresh
      ),
      "Content-Type": requestContentType,
      Accept: responseAcceptType,
    },
    ...formattedHeaders
  );

  return {
    method,
    url,
    headers,
    data,
  };
};

buildRequest.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  body: PropTypes.string,
  paramHeaders: PropTypes.array,
  basicAuth: PropTypes.string,
  authTokenRefresh: PropTypes.string,
  authMethod: PropTypes.string.isRequired,
};
