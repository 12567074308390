import React, { useState } from "react";

import ChevronRight from "../Svgs/ChevronRight";

const CollapsibleHeader = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <div
        className="flex items-center cursor-pointer hover:underline "
        onClick={toggleCollapse}
      >
        <h1 className="dpd-heading py-2 flex-grow">{title}</h1>
        <ChevronRight rotate={isCollapsed} />
      </div>
      {!isCollapsed && children}
    </>
  );
};

export default CollapsibleHeader;
