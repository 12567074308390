import * as SECTIONS from "./sections";

const sections = [
  SECTIONS.INTRODUCTION,
  SECTIONS.FLOW_INTEGRATIONS,
  SECTIONS.ACCESS_TOKEN,
  SECTIONS.REFRESH_ACCESS_TOKEN,
  SECTIONS.REMOVE_ACCESS_TOKEN,
  SECTIONS.TOKEN_EXPIRY,
  SECTIONS.TOKEN_INFORMATION,
  SECTIONS.ERROR_HANDLING,
];

export { sections as SECTIONS };
