import PropTypes from "prop-types";
import React from "react";

import OnPageNavItem from "./OnPageNavItem";

const OnPageNav = ({ steps }) => {
  return (
    <div className="hidden lg:block w-[250px] 2xl:mr-auto">
      <div className="dpd-border-left sticky top-0">
        <h2 className="dpd-text-small text-[#808285] pt-[24px] pl-[16px] pb-[8px]">
          ON THIS PAGE
        </h2>
        <ul className="list-none dpd-text-light">
          {steps.length > 0 && (
            <React.Fragment>
              <OnPageNavItem items={steps} />
            </React.Fragment>
          )}
        </ul>
      </div>
    </div>
  );
};

export default OnPageNav;

OnPageNav.protoTypes = {
  steps: PropTypes.array,
};
