import PropTypes from "prop-types";
import React from "react";

import { AUTHORISE, AUTHORISED } from "../../constants/docs/ALL/misc";

import { Button } from ".";
import Lock from "../Svgs/Lock";

const AuthoriseButton = ({ isAuth, ...props }) => (
  <Button
    styleVariant={isAuth ? "greenSolid" : "redSolid"}
    className="inline-flex"
    {...props}
    data-test="api-authorise-button"
  >
    <Lock className="w-4 h-4 mr-2" />
    {isAuth ? AUTHORISED : AUTHORISE}
  </Button>
);

AuthoriseButton.propTypes = {
  styleVariant: PropTypes.string,
  className: PropTypes.string,
  isAuth: PropTypes.bool,
};

export default AuthoriseButton;
