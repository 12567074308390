import { toast } from "react-hot-toast";

import { DATA_TEST } from "../../constants/testing/dataTest";

import { CopyIcon } from "../Svgs";

const CopyToClipboard = ({ text }) => {
  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!", {
      position: "bottom-right",
      duration: 1300,
      className: "dark:bg-[#2c2f33] dark:text-[#f3f4f6] ",
    });
  };

  return (
    <div className="flex dpd-text-code justify-between items-center bg-[#e0e0e0] dark:bg-[#303030] rounded-t-md group">
      <span className="ml-2"></span>
      <span
        className="mr-2 cursor-pointer flex items-center justify-center group-hover:flex"
        onClick={() => handleCopyClick()}
        data-test={DATA_TEST.COPY_TO_CLIPBOARD}
      >
        <CopyIcon />
      </span>
    </div>
  );
};

export default CopyToClipboard;
