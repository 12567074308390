export const DATA_TEST = {
  BASIC_AUTH_USERNAME: "basic-auth-username",
  BASIC_AUTH_PASSWORD: "basic-auth-password",
  ACCESS_TOKEN: "access-token-input",
  REFRESH_TOKEN: "refresh-token-input",
  AUTH_AUTHORISE: "basic-auth-authorise-button",
  AUTH_CLOSE: "basic-auth-close-button",
  OPEN_TRY_IT_OUT: "open-try-it-out-button",
  TRY_IT_OUT_EXECUTE: "execute-try-it-out-button",
  PARAM_TRY_IT_OUT: "parameter-try-it-out-button",
  SWAGGER_SECTION: "swagger-section",
  RESPONSE_JSON: "response-json",
  AUTH_MODAL: "auth_modal",
  CLIENT_ID: "client-id",
  RESPONSE_STATUS: "response-status",
  RESPONSE_OUTPUT: "response-output",
  REQUEST_OUTPUT: "request-output",
  SHIPMENT_ID: "shipmentid",
  PRINTER_TYPE: "printerType",
  PRINTER_DPI: "printerDpi",
  ACCEPT: "accept",
  REQUEST_EXAMPLES: "request-examples",
  COUNTRY_CODE: "countrycode",
  COMMODITY_CODE: "commoditycode",
  DELIVERY_COUNTRY_CODE: "deliverycountrycode",
  GEO_SERVICE_CODE: "geoservicecode",
  COPY_TO_CLIPBOARD: "copy-to-clipboard",
  PARCEL_NUMBERS: "array-input",
  ADD_PARCEL_NUMBER: "add-array-input",
  REMOVE_PARCEL_NUMBER: "remove-array-input",
  DELETE_ALL_PARCEL_NUMBERS: "delete-all-array-input",
  CODE_VIEWER_INPUT_MODE: "code-viewer-input-mode",
  CODE_VIEWER_OUTPUT_MODE: "code-viewer-output-mode",
  POSTCODE: "postcode",
  COUNTRY: "country",
  COLLECTION_CODE: "collectioncode",
};
