import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";

import "react-photo-view/dist/react-photo-view.css";
import { RoundedBorder } from "../Svgs";

const SVGViewer = ({ src }) => {
  return (
    <PhotoProvider>
      <div
        className="cursor-pointer transition-filter hover:brightness-90" // Apply hover brightness to show clickable
        style={{ width: "auto", height: "auto" }}
      >
        <RoundedBorder backgroundColor={"#dfe6ed"}>
          <PhotoView src={src}>
            <img src={src} style={{ width: "auto", height: "auto" }} />
          </PhotoView>
        </RoundedBorder>
      </div>
    </PhotoProvider>
  );
};

export default SVGViewer;
