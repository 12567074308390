import React from "react";

import { ScrollButton } from "../components/Actionable";
import { Dropdown } from "../components/Actionable/";
import GettingStartedHero from "../components/GettingStarted/Hero";
import GettingStartedStepCards from "../components/GettingStarted/StepCards";
import { GettingStartedSteps } from "../components/GettingStarted/StepsBody";
import { OnPageNav } from "../components/Navigation";
import NavCard from "../components/Navigation/NavCard";
import steps from "../constants/gettingStarted/gettingStartedSteps";
import useFormattedSteps from "../hooks/useFormattedSteps";
import useMenuItems from "../hooks/useMenuItemsSteps";

const GettingStarted = () => {
  const menuItems = useMenuItems(steps);
  const formattedSteps = useFormattedSteps(steps);

  return (
    <>
      <div id="back-to-top-anchor" className="w-[100%] max-w-[1000px]">
        <div className="px-[24px]">
          <Dropdown
            buttonContent="On this page"
            menuItems={menuItems}
            buttonClassName={{ styleVariant: "default" }}
            menuClassName="lg:hidden pt-[10px]"
          />
          <GettingStartedHero />
          <GettingStartedStepCards steps={steps} />
          <GettingStartedSteps steps={steps} />
          <ScrollButton />
          <NavCard
            navType="Next"
            navLocation={steps[0].title}
            isGettingStartedPage={true}
          />
        </div>
      </div>
      <OnPageNav steps={formattedSteps} />
    </>
  );
};

export default GettingStarted;
