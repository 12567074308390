import PropTypes from "prop-types";

const Select = ({
  labelTitle,
  onChange,
  selectList,
  required,
  value,
  hideTitle = false,
  fixedWidth = false,
  dataTest,
  idOverride,
}) => {
  const borderStyle = required && !value ? "border-red-500" : "border-gray-300";
  return (
    <div className="flex flex-wrap w-min">
      {!hideTitle && (
        <label
          htmlFor={idOverride || labelTitle}
          className={`dpd-text-light block pt-2 pb-2 mt-[10px] ${borderStyle}`}
        >
          {labelTitle}
        </label>
      )}
      <select
        data-test={dataTest || labelTitle}
        id={idOverride || labelTitle}
        className={`select-arrow pt-2 pb-2 pr-9 pl-3 border ${borderStyle} rounded-md dpd-text-button ${fixedWidth ? "w-[200px]" : ""}`}
        onChange={(e) => onChange(e)}
        value={value}
      >
        {labelTitle && (
          <option value="" disabled>
            {labelTitle}
          </option>
        )}
        {selectList.map((name) => (
          <option value={name} key={name}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;

Select.propTypes = {
  labelTitle: PropTypes.string,
  onChange: PropTypes.func,
  selectList: PropTypes.array,
  required: PropTypes.bool,
  value: PropTypes.string,
  hideTitle: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  dataTest: PropTypes.string,
  idOverride: PropTypes.string,
};
