import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../constants";
import { ThemeContext } from "../../context/ThemeContext";
import { sanitisePath, selectGettingStartedImg } from "../../helpers/strings";

import { Card } from "../Actionable/";

const GettingStartedStepCards = ({ steps }) => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <>
      <div className="mb-[14px]">
        <p className="text-[24px] tracking-heading">
          Get Started in {steps.length} Steps
        </p>
        <div className="dpd-border-bottom 1p mt-[8px] mb-[16px] solid" />
        <p>
          Get set up in no time and be ready for API integration. Follow our
          four-step guide to get started
        </p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between pb-[24px]">
        {steps &&
          steps.map((step, index) => (
            <div key={step.title} className="flex justify-center flex-wrap">
              <Link to={`${PATHS.GETTING_STARTED}/${sanitisePath(step.title)}`}>
                <Card
                  text={step.title}
                  imgSrc={selectGettingStartedImg(
                    step.title.replace(/ .*/, "").toLowerCase() ===
                      "select-api/sandbox"
                      ? "select"
                      : step.title.replace(/ .*/, ""),
                    theme
                  )}
                  counter={index + 1}
                  styleVariant={"large"}
                />
              </Link>
            </div>
          ))}
      </div>
    </>
  );
};
export default GettingStartedStepCards;

GettingStartedStepCards.protoTypes = {
  steps: PropTypes.array,
};
