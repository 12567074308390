import React from "react";

import {
  AuthoriseButton,
  DownloadDocsDropdown,
  TryItOutButton,
} from "../../components/Actionable";
import RequestTable from "../../components/Tables/RequestTable";
import BodyParagraph from "../../components/Typography/BodyParagraph";
import MessageCard from "../../components/Typography/MessageCard";

const OVERVIEW = {
  summary: "Overview",
  body: (
    <>
      <BodyParagraph>
        After you’ve obtained your authentication token/s these are to be used
        to integrate with DPD UK Web services and allows you to test your
        application/s using our API within the API sandbox environment.
      </BodyParagraph>
      <BodyParagraph>
        Please see supporting information provided below to aid with your
        development process and give information regarding API Integration.
      </BodyParagraph>
    </>
  ),
};

const SANDBOX_VS_LIVE = {
  summary: "Sandbox Environment vs Live Environment",
  body: (
    <>
      <BodyParagraph>
        URL’S provided within this guide and the “try it out” functionality
        relate to the Sandbox environment, this is a dedicated development only
        environment provided by ourselves that can be used to assist with your
        development process.
      </BodyParagraph>
      <BodyParagraph>
        The test URL’S and endpoints are a copy of the live production API's so
        functionality will remain consistent to allow you to test effectively
        and is independent of the live environment. As the sandbox environment
        is independent of the live environment it is required to manage API keys
        independently , so in order to ship via live API’s it is required to
        create API key for the live environment as sandbox and live keys are not
        interchangeable.
      </BodyParagraph>
      <BodyParagraph>
        This allows you to proceed with development work and test if your
        integration is working as expected before switching to the production
        live environment.
      </BodyParagraph>
      <BodyParagraph>
        Live URLs can be found under the Production section once your testing
        has been completed and you are ready to submit your test pack to the
        customer integration team and switch your implementation to live
        environment once your integration has been approved.
      </BodyParagraph>
    </>
  ),
};

const HOW_TO_USE_PORTAL = {
  summary: "Introduction on how to use Portal Test Functionality",
  body: (
    <>
      <BodyParagraph>
        Once you have generated or obtained your API Key/Secret pair it is
        possible to try an API and execute an API Request from the DPD UK API
        Portal Website without the need of additional application/s.
      </BodyParagraph>
      <BodyParagraph>
        <div className="flex flex-col lg:flex-row justify-between mb-0 lg:mb-auto">
          At the top of each API page there is an Authorise button. When
          clicking this button this will display a dialog box and list Available
          authorisations such as basicAuth , bearerAccessToken &
          bearerRefreshToken.
          <div>
            <AuthoriseButton />
          </div>
        </div>
      </BodyParagraph>
      <BodyParagraph>
        This must be populated with the relevant authorisation method for the
        API you wish to use to be able to try the API. Once details are input
        and the relevant authorise button is clicked the authorization
        information is set.
      </BodyParagraph>
      <BodyParagraph>
        <div className="flex justify-between">
          Each available API listed within the specification has a "Try it Out"
          Button that can be found by clicking the button located at the top
          right of the page, This will then display an additional pane on the
          right hand side of the screen and the try it now button will be
          displayed.
          <TryItOutButton />
        </div>
      </BodyParagraph>
      <BodyParagraph>
        When clicked this will give options to execute or clear and give prompts
        to provide any required parameters or provides an example request body
        that can be amended for certain API’s (If applicable) (or present the
        authorisation dialog box described above if not already set.)
      </BodyParagraph>
      <BodyParagraph>
        When clicking the “execute” button this will send the request to the
        relevant Sandbox endpoint along with any parameters / payload and
        display an example of request code (cURL by default) (This can be
        amended to display different code examples available), The URL the
        request was sent to and any applicable response from the sandbox API.
      </BodyParagraph>
      <BodyParagraph>
        Clicking the “Clear” button will remove any on screen configured
        parameters or example payload amendments.
      </BodyParagraph>
      <MessageCard
        messageType="note"
        message="Depending on the type of account you hold (DPD or DPD Local) there
        may be a requirement to amend the data in the example requests given as
        these possess details in relation to a DPD account."
      />
      <BodyParagraph>
        If a DPD Local account is held you will need to amend “networkCode” in
        the request as the network code provided in the example relates to a DPD
        networkCode and will not work if a DPD Local Account is held.
      </BodyParagraph>
    </>
  ),
};

const REQUEST_RESPONSE_FORMAT = {
  summary: "Request / Response Format",
  body: (
    <>
      <BodyParagraph>
        DPD UK API’s support JSON as a valid content type and accept type.
      </BodyParagraph>
      <BodyParagraph>
        The destination host supports HTTPS protocol only and certain headers
        are required by DPDgroup UK Web services in every request unless stated,
        these are provided below:
      </BodyParagraph>
      <BodyParagraph>
        Authorization (required for all API Requests)
        <br />
        Client-Id (required for all API Requests apart from Get Access Token
        API)
        <br />
        Content-Type (required for all API Requests)
        <br />
        Accept (required for all API Requests)
        <br />
      </BodyParagraph>
    </>
  ),
};

const OPEN_API_DOCUMENTATION = {
  summary: "Technical documentation",
  body: (
    <>
      <BodyParagraph>
        Technical details for all DPD UK API services are provided within this
        site and can be found within the sidebar located within the left hand
        pane.
      </BodyParagraph>
      <BodyParagraph>
        <div className="flex flex-col lg:flex-row justify-between mb-0 lg:mb-auto">
          All technical specifications are written using OpenAPI specifications
          and are available to download in JSON or YAML format to import into
          the tool of your choice as a resource to assist with your development
          process. These can be downloaded by using the “Download Docs” button
          located and the top left of each API Specification page.
          <div>
            <DownloadDocsDropdown menuItems={[]} />
          </div>
        </div>
      </BodyParagraph>
    </>
  ),
};

const SANDBOX_INFORMATION = {
  summary: "Sandbox Information",
  body: (
    <>
      <BodyParagraph.Header>Rate Limiting</BodyParagraph.Header>
      <BodyParagraph>
        The DPD UK API sandbox features stricter rate limits than the production
        environment, this is to enable fair use for all customers, upon being
        provided sign off from the Customer Integration team to be able to
        utilize the production environment these limits are increased.
      </BodyParagraph>
      <BodyParagraph.Header>Sandbox Labels</BodyParagraph.Header>
      <MessageCard
        messageType="note"
        message=" Labels generated within the sandbox environment will have variations compared to those produced within the production environment."
      />
      <BodyParagraph>
        When submitting a test pack for sign off, please ensure that the labels
        are generated specifically in the production environment to allow the
        Customer Integration team to test effectively and the labels output are
        representative of labels that will be produced within the production
        environment.
      </BodyParagraph>
    </>
  ),
};

const INTEGRATION_EXAMPLES = {
  summary: "Integration Examples",
  body: (
    <>
      <BodyParagraph>
        {/*TODO everything in quotes needs a link to the relevant content*/}
        <div className="flex justify-between">
          When using the "Try it Out" functionality located within this site,
          When executing requests this will allow you to generate example code
          to reproduce the request locally
          <TryItOutButton />
        </div>
        <br />
        The following is an example of our formats that are available for your
        reference.
        <div className="flex justify-between !max-w-screen !w-full">
          <RequestTable
            curlCommand={
              "curl -X 'GET' \\\n 'https://developers.api.customers.dpd.co.uk/v1/customer/auth/access' \\\n -H 'accept: application/json' \\\n -H 'Authorization: Basic YTpi'"
            }
            showHeader={false}
          />
        </div>
      </BodyParagraph>
    </>
  ),
};
const sections = [
  OVERVIEW,
  REQUEST_RESPONSE_FORMAT,
  HOW_TO_USE_PORTAL,
  SANDBOX_VS_LIVE,
  SANDBOX_INFORMATION,
  OPEN_API_DOCUMENTATION,
  INTEGRATION_EXAMPLES,
];
export default sections;
