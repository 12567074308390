import { Toaster } from "react-hot-toast";

import { CloseNavBtn, SideNav, TopNav } from "../components/Navigation";

const Layout = ({ apis, allMethods, children }) => {
  return (
    <div className="h-screen flex flex-col">
      <TopNav />
      <div className="h-screen flex 2xl:justify-between">
        <SideNav routes={apis} allMethods={allMethods} />
        <CloseNavBtn />
        {children}
      </div>
      <Toaster position="bottom-right" limit={3} />
    </div>
  );
};

export default Layout;
