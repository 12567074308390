import React, { useContext } from "react";

import { DATA_TEST } from "../../constants/testing/dataTest";
import { TryItOutContext } from "../../context/TryItOutContext";

import { Button } from ".";
import EditIcon from "../Svgs/EditIcon";

const TryItOutButton = () => {
  const { showTryItOut, setShowTryItOut } = useContext(TryItOutContext);

  return (
    <Button
      className="ml-[5px] hidden sm:block"
      styleVariant={!showTryItOut ? "redSolid" : "greenSolid"}
      data-test={DATA_TEST.OPEN_TRY_IT_OUT}
      onClick={() => setShowTryItOut(!showTryItOut)}
    >
      <EditIcon />
    </Button>
  );
};

export default TryItOutButton;
