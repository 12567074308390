import Error404Message from "../components/Typography/Error404Message";

const PageNotFound = () => {
  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="col-span-2">
        <div className={`lg:px-[48px] lg:pr-[35%] mx-auto lg:mx-0`}>
          <Error404Message />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
