import React from "react";

const ClearIcon = () => {
  return (
    <svg
      className="w-4 h-4 text-gray-500"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l5.707-5.707a1 1 0 011.414 1.414L11.414 10l5.707 5.707a1 1 0 01-1.414 1.414L10 11.414l-5.707 5.707a1 1 0 01-1.414-1.414L8.586 10L2.879 4.293a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default ClearIcon;
