import PropTypes from "prop-types";
import React, { lazy, Suspense, useContext } from "react";

import { DATA_TEST } from "../../constants/testing/dataTest";
import { ThemeContext } from "../../context/ThemeContext";

const CodeViewer = lazy(() => import("../Typography/CodeViewer"));
import Spinner from "../Spinner";

const JsonBlock = ({ value, fullPadding = true }) => {
  const { theme } = useContext(ThemeContext);

  const customTheme = {
    property: {
      color: theme === "dark" ? "#ADB5BD" : "#282C34",
    },
    string: {
      color: "#509E2F",
    },
    number: {
      color: "#E5444B",
    },
  };

  if (!value) {
    return null;
  }

  return (
    <div className="relative grid">
      <Suspense fallback={<Spinner inline={true} />}>
        <CodeViewer
          value={value}
          maxHeight={"500px"}
          className={"overflow-x-auto"}
          data-test={DATA_TEST.RESPONSE_JSON}
        />
      </Suspense>
    </div>
  );
};
export default JsonBlock;

JsonBlock.propTypes = {
  value: PropTypes.string,
  fullPadding: PropTypes.bool,
};
