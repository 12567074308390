import { cva } from "class-variance-authority";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { classNames } from "../../helpers/strings";

import Counter from "../Typography/Counter";

const card = cva("card", {
  variants: {
    styleVariant: {
      small: ["min-w-[160px]", "min-h-[160px]"],
      large: ["min-w-[190px]", "min-h-[200px]"],
    },
  },
  compoundVariants: [{ styleVariant: "small" }],
  defaultVariants: {
    styleVariant: "small",
  },
});

const cardImg = cva("cardImg", {
  variants: {
    styleVariant: {
      small: ["w-[60px", " h-[60px]", "mb-[8px]"],
      large: ["w-[100px]", "h-[100px]", " mb-[25px]"],
    },
  },
  compoundVariants: [{ styleVariant: "small" }],
  defaultVariants: {
    styleVariant: "small",
  },
});

const Card = ({ className, styleVariant, ...props }) => {
  const { imgSrc, link, text, counter } = props;

  const cardContent = (
    <>
      {counter && <Counter count={counter} />}
      <div
        className={classNames(
          card({ styleVariant, className }),
          "container border-solid hover:bg-gray-100 dark:hover:bg-[#444950] transition duration-300 ease-in-out dark:bg-[#2a2d30] border-[1.5px] border-black dark:border-[#444950] rounded-md flex flex-col justify-center items-center"
        )}
      >
        <img
          src={imgSrc}
          alt={`${text} logo`}
          className={classNames(
            cardImg({ styleVariant, className }),
            "self-center"
          )}
        />
        <p className="text-center">{text}</p>
      </div>
    </>
  );

  return (
    <div className="mr-[16px]">
      {link ? <Link to={link}>{cardContent}</Link> : cardContent}
    </div>
  );
};

export default Card;

Card.propTypes = {
  className: PropTypes.string,
  styleVariant: PropTypes.string,
};
