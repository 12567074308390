import PropTypes from "prop-types";
import React from "react";

import { Dropdown } from ".";

const DownloadDocsDropdown = ({ menuItems, ...props }) => (
  <Dropdown
    buttonContent="Download Docs"
    menuItems={menuItems}
    buttonClassName={{ styleVariant: "redButton" }}
    requireScrolling={false}
    {...props}
  />
);

DownloadDocsDropdown.propTypes = {
  menuItems: PropTypes.array,
};

export default DownloadDocsDropdown;
