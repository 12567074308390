const ApiStatusPill = () => {
  return (
    <a
      href={process.env.REACT_APP_STATUS_PAGE}
      target="_blank"
      rel="noopener noreferrer"
    >
      <button className="text-blue-500 bg-blue-200 hover:bg-blue-500 hover:text-blue-200 dark:bg-blue-700 dark:text-blue-200 dark:hover:bg-blue-200 dark:hover:text-blue-700 px-2 py-1 rounded-full text-xs mr-2 transition-all ease-in-out duration-500">
        API Status
      </button>
    </a>
  );
};

export default ApiStatusPill;
