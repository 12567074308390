import PropTypes from "prop-types";
import React, { useState } from "react";

const CodeEditor = ({ initialValue, className, minHeight, onChange }) => {
  const [body, setEditBody] = useState(initialValue);

  return (
    <textarea
      className={`p-[16px] !bg-[#0000000d] dark:!bg-[#2B2B2B] w-full min-h-[${minHeight}px] dpd-text-code ${className}`}
      value={body}
      onChange={(e) => {
        setEditBody(e.target.value);
        onChange?.(e.target.value);
      }}
    />
  );
};

CodeEditor.propTypes = {
  initialValue: PropTypes.string.isRequired,
  className: PropTypes.string,
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func,
};

export default CodeEditor;
