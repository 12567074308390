import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { HTTP_METHODS, INTRODUCTION } from "../../constants";
import { joinWithHyphen, sanitisePath } from "../../helpers/strings";
import {
  getNavItemGrandChildName,
  getNavItemChildName,
  getNavItemName,
  getNavItemChevronName,
} from "../../helpers/testing";

import ChevronRight from "../Svgs/ChevronRight";
import ApiMethodIndicator from "../Typography/ApiMethodIndicator";

const SideNavItem = ({
  name,
  isActive,
  versions,
  activeVersion,
  customPath,
  postMethods,
  getMethods,
}) => {
  const [collapseNav, setCollapsNav] = useState(true);
  const [collapseSpec, setCollapseSpec] = useState(true);
  const { pathname } = useLocation();
  const introductionIsActive =
    customPath && pathname === "/"
      ? true
      : pathname?.includes(INTRODUCTION) &&
        pathname?.includes(name?.toLowerCase());

  useEffect(() => {
    let isActivePath = false;
    let isMethodActive = false;

    if (customPath) {
      isActivePath =
        pathname === "/" && name === "Getting Started"
          ? true
          : pathname.includes(`/${sanitisePath(name)}`);
    } else {
      isActivePath = pathname.includes(
        `/${sanitisePath(name)}/${sanitisePath(activeVersion)}`
      );
      isMethodActive = [...(postMethods || []), ...(getMethods || [])].some(
        (method) => pathname.includes(joinWithHyphen(method))
      );
    }

    if (isActivePath || isMethodActive) {
      setCollapsNav(false);
      if (isMethodActive) {
        setCollapseSpec(false);
      }
    } else {
      setCollapsNav(true);
    }
  }, [pathname, name, activeVersion, postMethods, getMethods, customPath]);

  const handleSpecClick = () => {
    setCollapseSpec(!collapseSpec);
  };

  return (
    <li
      className={`dpd-border-top ${
        isActive
          ? "bg-[#0000000d] dark:bg-[#272729] border-r-[3px] border-[#DC0032]"
          : ""
      }`}
    >
      <div
        className={"flex py-[20px] pl-[20px] items-center cursor-pointer"}
        onClick={() => setCollapsNav(!collapseNav)}
      >
        {customPath ? (
          <p className={isActive ? "text-[#DC0032]" : ""}>{name}</p>
        ) : (
          <span
            className={isActive ? "text-[#DC0032]" : ""}
            data-test={getNavItemName(name)}
          >
            {name}
          </span>
        )}
        <ChevronRight
          rotate={collapseNav}
          data-test={getNavItemChevronName(name)}
        />
      </div>
      {!collapseNav && (
        <div className={`pl-[28px] mt-[-15px] pb-[10px]`}>
          <Link
            className={`block mb-[8px] ${
              introductionIsActive ? "text-[#DC0032]" : ""
            }`}
            to={
              customPath
                ? `/${sanitisePath(name)}`
                : `/${name.toLowerCase()}/introduction`
            }
            data-test={getNavItemChildName(name, "Introduction")}
          >
            Introduction
          </Link>
          {versions.map((version, index) => (
            <div key={version.name}>
              {customPath ? (
                <Link
                  to={`/${sanitisePath(name)}/${sanitisePath(version.name)}`}
                  className={`flex items-center mb-[8px] cursor-pointer ${
                    activeVersion === version.name && isActive
                      ? "text-[#DC0032]"
                      : ""
                  }`}
                  data-test={getNavItemChildName(name, version.name)}
                >
                  {version.name}
                </Link>
              ) : (
                <div
                  className={`flex items-center mb-[8px] cursor-pointer ${
                    activeVersion === version.name && isActive
                      ? "text-[#DC0032]"
                      : ""
                  }`}
                  onClick={handleSpecClick}
                  data-test={getNavItemChildName(name, "Specification")}
                >
                  {index === 0
                    ? "Specification"
                    : `Specification ${version.name}`}
                  <ChevronRight rotate={collapseSpec} />
                </div>
              )}
              {!collapseSpec && (
                <div className="pl-2 mt-4 mr-2 pb-2">
                  {postMethods.map((method) => (
                    <Link
                      key={method + version.name}
                      className={`block mb-2 ${
                        pathname ===
                        `/${sanitisePath(name)}/${sanitisePath(version.name)}/${joinWithHyphen(method)}`
                          ? "text-red-600"
                          : ""
                      }`}
                      to={`/${sanitisePath(name)}/${sanitisePath(version.name)}/${joinWithHyphen(method)}`}
                      data-test={getNavItemGrandChildName(
                        name,
                        version.name,
                        method
                      )}
                    >
                      <ApiMethodIndicator method={HTTP_METHODS.POST} />
                      {method}
                    </Link>
                  ))}
                  {getMethods.map((method) => (
                    <Link
                      key={method + version.name}
                      className={`block mb-2 ${
                        pathname ===
                        `/${sanitisePath(name)}/${sanitisePath(version.name)}/${joinWithHyphen(method)}`
                          ? "text-red-600"
                          : ""
                      }`}
                      to={`/${sanitisePath(name)}/${sanitisePath(version.name)}/${joinWithHyphen(method)}`}
                      data-test={getNavItemGrandChildName(
                        name,
                        version.name,
                        method
                      )}
                    >
                      <ApiMethodIndicator method={HTTP_METHODS.GET} />
                      {method}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </li>
  );
};

export default SideNavItem;
