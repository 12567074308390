import { useMemo } from "react";

import useFormattedSteps from "./useFormattedSteps";

export default function useMenuItems(steps) {
  const formattedSteps = useFormattedSteps(steps);

  const menuItems = useMemo(() => {
    return formattedSteps.map((step) => ({
      label: step.summary,
      props: {},
    }));
  }, [formattedSteps]);

  return menuItems;
}
