import React from "react";
import ReactMarkdown from "react-markdown";

import { METHODS } from "../../constants";

import { CollapsibleHeader } from "../Actionable/";
import { ScrollButton } from "../Actionable/";
import NestedTable from "../Swagger/NestedTable";
import ExampleResponseTable from "../Tables/ExampleResponseTable";
import { MarkdownComponents } from "../Typography/Markdown";
import Title from "../Typography/Title";

const SwaggerSchema = ({
  schema,
  responses,
  summary,
  method,
  urlPath,
  showTryItOut,
  description,
}) => {
  return (
    <div className="w-full overflow-x-auto sm:overflow-x-visible rounded-lg">
      <h1 className="dpd-heading py-2" id="back-to-top-anchor">
        {summary}
      </h1>
      <div className="dpd-border-bottom">
        <div className="flex items-center flex-wrap">
          <Title method={method} />
          {urlPath}
        </div>
      </div>
      {description && (
        <ReactMarkdown
          className="text-[14px] py-2"
          components={MarkdownComponents}
        >
          {description}
        </ReactMarkdown>
      )}
      {schema && (
        <CollapsibleHeader title="Schema">
          {method === METHODS.POST && (
            <p className="text-[12px]">
              Please note: A red asterisk{" "}
              <span className="text-red-600">*</span> provided next to field
              name within the Schema denotes a mandatory field
            </p>
          )}
          <NestedTable data={schema} />
          <div className="pt-8" />
        </CollapsibleHeader>
      )}
      {responses && <ExampleResponseTable responses={responses} />}
      <ScrollButton showTryItOut={showTryItOut} />
    </div>
  );
};

export default SwaggerSchema;
