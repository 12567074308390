import * as SECTIONS from "./sections";
import { SANDBOX_VS_LIVE } from "./sections";

const sections = [
  SECTIONS.INTRODUCTION,
  SECTIONS.FLOW_INTEGRATIONS,
  SECTIONS.PRELABELLED,
  SECTIONS.SANDBOX_VS_LIVE,
  SECTIONS.SANDBOX_TEST,
  SECTIONS.RATE_LIMITING,
  SECTIONS.ERROR_HANDLING,
];

export { sections as SECTIONS };
